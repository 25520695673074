/* eslint-disable no-unused-vars */
/* eslint-disable max-len */
import React, {useState, useEffect} from 'react';
import {
  CardMedia, Grid,
  Card, CardActionArea, CardActions, CardContent, Paper,
  Button,
} from '@material-ui/core';
import moment from 'moment';

const imageUrlPattern = /\.(jpg|jpeg|png|gif|bmp|webp)$/i;
const pattern = /lofty/i;

const formatCurrency = (value) => {
  if (!value && value !== 0) return 'Ningún dato';
  return new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(value);
};

const CardItem = ({
  database, doc, fields, fieldsDatabase,
}) => {
  const fieldLabel = fieldsDatabase?.find((item) => item.isLabel);
  let fieldsShow = fields?.filter((item) => (
    item.field !== 'loftyOriginalName' && item.field !== '_id'
    && item.field !== 'loftyUrl' && item.field !== 'loftyCreatedAt'
    && item.field !== 'actions'
  ));
  if (fields?.length > 7) {
    fieldsShow = fieldsShow?.slice(0, 7);
  }
  const fieldAction = fields?.find((item) => (
    item.field === 'actions'
  ));
  return (
    <Card>
      <div>
        {
          database.isBucket ? (
            <CardMedia
              component="img"
              alt="C"
              height="140"
              image={
                imageUrlPattern.test(doc.loftyUrl) ? doc.loftyUrl : '/file.png'
              }
              title={fieldLabel ? doc[fieldLabel.name] : doc.loftyOriginalName}
            />
          ) : <></>
        }
        <CardContent>
          <div className="my-2">
            <p>
              <b>_id:</b>
            </p>
            <p>
              {doc._id}
            </p>
          </div>
          {
            database.isBucket ? (
              <>
                <div className="my-2">
                  <p>
                    <b>Nombre Original:</b>
                  </p>
                  <p>
                    {doc.loftyOriginalName}
                  </p>
                </div>
                <div className="my-2">
                  <p>
                    <b>URL:</b>
                  </p>
                  <p
                    style={{
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                    }}
                  >
                    {doc.loftyUrl}
                  </p>
                </div>
              </>
            ) : <></>
          }
          <div className="my-2">
            <p>
              <b>Fecha de creación:</b>
            </p>
            <p
              style={{
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
            >
              {
                doc.loftyCreatedAt ? (
                  moment(doc.loftyCreatedAt).format('DD/MM/YYYY')
                ) : 'No hay dato'
              }
            </p>
          </div>
          {
            fieldsShow?.map((field) => {
              let fieldDataLabel = fieldsDatabase?.find((item) => item.name === field.field);
              if (!fieldDataLabel) {
                if (pattern.test(field.field)) {
                  const slug = field.field.split('_')[1];
                  fieldDataLabel = fieldsDatabase?.find((item) => item.name === slug);
                }
              }
              if (field.render) {
                return (
                  <div className="my-2" key={field.field}>
                    <p>
                      <b>
                        {fieldDataLabel?.label}
                      </b>
                    </p>
                    <p>
                      {field.render(doc)}
                    </p>
                  </div>
                );
              }
              return (
                <div className="my-2" key={field.field}>
                  <p>
                    <b>
                      {fieldDataLabel?.label}
                    </b>
                  </p>
                  <p>
                    {fieldDataLabel?.type === 'number'
                      ? formatCurrency(doc[field.field])
                      : (doc[field.field] || 'Ningún dato')}
                  </p>
                </div>
              );
            })
          }
        </CardContent>
      </div>
      <CardActions>
        {fieldAction?.render(doc)}
      </CardActions>
    </Card>
  );
};

const GridCardPaginated = ({
  data, fields, limit, totalRegister,
  handleNext,
  fieldsDatabase, database,
}) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [pages, setPages] = useState([]);
  const [total, setTotal] = useState();
  useEffect(() => {
    const totalPage = totalRegister / limit;
    setTotal(parseFloat(totalPage.toFixed(0)));
    const newPages = [];
    for (let index = 0; index < totalPage; index += 1) {
      newPages.push({
        label: `${index + 1}`,
        number: index,
      });
    }
    setPages(newPages);
  }, [limit, totalRegister]);
  return (
    <div>
      <Grid container spacing={2}>
        {
          data?.map((doc) => (
            <Grid lg={4} md={4} xs={12} key={doc._id} item>
              <CardItem
                database={database}
                doc={doc}
                fields={fields}
                fieldsDatabase={fieldsDatabase}
              />
            </Grid>
          ))
        }
      </Grid>
      {
        data.length < totalRegister - 1 ? (
          <div className="mt-4 mb-4 text-center">
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                if (handleNext) {
                  handleNext(currentPage + 10);
                }
                setCurrentPage(currentPage + 10);
              }}
            >
              Mostrar más
            </Button>
          </div>
        ) : <></>
      }
    </div>
  );
};

export default GridCardPaginated;
