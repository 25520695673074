export const localStorageMiddleware = (store) => (next) => (action) => {
  const result = next(action);

  if (action.type === 'SET_PROJECT_DATA') {
    const currentState = store.getState().ia;
    const newState = {
      ...currentState,
      ...action.payload,
    };
    localStorage.setItem('iaProjectData', JSON.stringify(newState));
  } else if (action.type === 'RESET_PROJECT_DATA') {
    localStorage.setItem('iaProjectData', JSON.stringify(store.getState().ia));
  }

  return result;
};
