import React, {useState, useEffect} from 'react';
import {Link} from 'react-router-dom';
import {
  Grid, MenuItem,
  ListItemIcon, Typography,
  MenuList, Box, Hidden,
} from '@material-ui/core';
import {withStyles, makeStyles} from '@material-ui/core/styles';
import {getLoggedUser} from 'api-lofty';
import Layout from './Dashboard';
import imgLogo from '../img/dashhboard/logo-w_1.png';
import imgInit1 from '../img/icons/plantillas_w.svg';
import imgInit2 from '../img/icons/plantillas_b.svg';
import imgInv1 from '../img/icons/invitar_w.svg';
import imgInv2 from '../img/icons/invitar_b.svg';
import imgProf1 from '../img/icons/perfil_w.svg';
import imgProf2 from '../img/icons/perfil_b.svg';
// import imgPay1 from '../img/icons/creditcard_w.svg';
// import imgPay2 from '../img/icons/creditcard_b.svg';
import imgActivity1 from '../img/icons/actividad_w.svg';
import imgActivity2 from '../img/icons/actividad_b.svg';
import imgProject1 from '../img/icons/proyecto_w.svg';
import imgProject2 from '../img/icons/proyecto_b.svg';

const StyledMenuProject = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgProject1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgProject2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgProject2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuPlantilla = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgInit1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgInit2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgInit2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuInvite = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgInv1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgInv2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgInv2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const StyledMenuProf = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgProf1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgProf2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgProf2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

// const StyledMenuPayment = withStyles((theme) => ({
//   root: {
//     color: '#fff',
//     '& .MuiListItemIcon-root': {
//       background: `url(${imgPay1})`,
//       width: '20px',
//       height: '20px',
//       backgroundSize: 'contain',
//       backgroundRepeat: 'no-repeat',
//     },
//     '&:hover': {
//       backgroundColor: theme.palette.common.white,
//       borderTopLeftRadius: 12,
//       borderBottomLeftRadius: 12,
//       color: 'black',
//       '& .MuiListItemIcon-root': {
//         background: `url(${imgPay2})`,
//         width: '20px',
//         height: '20px',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//       },
//       '&:focus': {
//         backgroundColor: theme.palette.common.white,
//       },
//     },
//     '&:focus': {
//       borderTopLeftRadius: 12,
//       borderBottomLeftRadius: 12,
//       backgroundColor: theme.palette.common.white,
//       color: 'black',
//       '& .MuiListItemIcon-root': {
//         background: `url(${imgPay2})`,
//         width: '20px',
//         height: '20px',
//         backgroundSize: 'contain',
//         backgroundRepeat: 'no-repeat',
//       },
//     },
//     [theme.breakpoints.down('lg')]: {
//       '& .MuiListItemIcon-root': {
//         width: '17px',
//         height: '17px',
//       },
//       '&:hover': {
//         '& .MuiListItemIcon-root': {
//           width: '17px !important',
//           height: '17px !important',
//         },
//       },
//       '&:focus': {
//         '& .MuiListItemIcon-root': {
//           width: '17px !important',
//           height: '17px !important',
//         },
//       },
//     },
//   },
// }))(MenuItem);

const StyledMenuActivity = withStyles((theme) => ({
  root: {
    color: '#fff',
    '& .MuiListItemIcon-root': {
      background: `url(${imgActivity1})`,
      width: '20px',
      height: '20px',
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    '&:hover': {
      backgroundColor: theme.palette.common.white,
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      color: 'black',
      '& .MuiListItemIcon-root': {
        background: `url(${imgActivity2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },
    '&$selected': {
      backgroundColor: theme.palette.common.white,
      color: 'black',
      borderTopLeftRadius: 12,
      borderBottomLeftRadius: 12,
      '& .MuiListItemIcon-root': {
        background: `url(${imgActivity2})`,
        width: '17px !important',
        height: '17px !important',
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      },
    },

    [theme.breakpoints.down('lg')]: {
      '& .MuiListItemIcon-root': {
        width: '17px',
        height: '17px',
      },
    },

    [theme.breakpoints.down('md')]: {
      fontSize: '12px',
    },
  },
  selected: {},
}))(MenuItem);

const useStyle = makeStyles((theme) => ({
  menuItemTypo: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('1280')]: {fontSize: '11px'},
    [theme.breakpoints.down('1100')]: {fontSize: '9px'},
    [theme.breakpoints.down('950')]: {fontSize: '12px'},
  },
  drawerPaper: {
    width: 240,
    backgroundColor: '#144999',
  },
}));

const PrincipalDashboard = ({
  children, history, handleTutorial, token,
}) => {
  const [isActiveUser, setIsActiveUser] = useState(false);
  useEffect(async () => {
    try {
      const getUserInfo = await getLoggedUser({token});
      if (getUserInfo.user.verified) {
        setIsActiveUser(true);
      }
    } catch (error) {
      //
    }
    // getLoggedUser({token}).then((res) => {
    //   console.log(res);
    //   if (res.user.verified) {
    //     console.log(res.user.verified);
    //     setIsActiveUser(true);
    //   }
    // }).catch((error) => {
    //   console.log(error);
    // });
  }, []);
  const limitedDrawer = (
    <Box>
      <Link to="/dashboard">
        <Box
          component="img"
          alt="logo"
          style={{
            zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
          }}
        />
      </Link>
      <MenuList
        autoFocusItem
        style={{
          marginLeft: '5%',
        }}
      >
        <StyledMenuProf
          selected={window.location.pathname === '/dashboard/profile'}
          onClick={() => history.push('/dashboard/profile')}
          id="profile_platform"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Mi Perfil
          </Typography>
        </StyledMenuProf>
      </MenuList>
    </Box>
  );

  const drawerMenu = (
    <Box>
      <Link to="/dashboard">
        <Box
          component="img"
          src={imgLogo}
          alt="logo"
          style={{
            zIndex: 1, marginTop: 'auto', marginBottom: 'auto',
          }}

        />
      </Link>
      <MenuList
        autoFocusItem
        style={{
          marginLeft: '5%',
        }}
      >
        <StyledMenuProject
          selected={window.location.pathname === '/dashboard'}
          onClick={() => history.push('/dashboard')}
          id="projects_platform"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Proyectos
          </Typography>
        </StyledMenuProject>
        <StyledMenuPlantilla
          selected={window.location.pathname === '/dashboard/templates'}
          onClick={() => history.push('/dashboard/templates')}
          id="templates_platform"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Plantillas
          </Typography>
        </StyledMenuPlantilla>
        <StyledMenuInvite
          selected={window.location.pathname === '/dashboard/invitations'}
          onClick={() => history.push('/dashboard/invitations')}
          id="invites_platform"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Invitaciones
          </Typography>
        </StyledMenuInvite>

        <StyledMenuProf
          selected={window.location.pathname === '/dashboard/profile'}
          onClick={() => history.push('/dashboard/profile')}
          id="profile_platform"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Mi Perfil
          </Typography>
        </StyledMenuProf>
        <StyledMenuProf
          selected={window.location.pathname === '/dashboard/calendar'}
          onClick={() => history.push('/dashboard/calendar')}
          id="schedule_meeting"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Agendar Reunión
          </Typography>
        </StyledMenuProf>
        <StyledMenuProf
          selected={window.location.pathname === '/dashboard/affiliates'}
          onClick={() => history.push('/dashboard/affiliates')}
          id="affiliates_info"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Afiliados
          </Typography>
        </StyledMenuProf>

        <StyledMenuInvite
          selected={window.location.pathname === '/dashboard/redeem'}
          onClick={() => history.push('/dashboard/redeem')}
          id="profile_platform"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Canjear Codigo
          </Typography>
        </StyledMenuInvite>
        <Hidden xlDown>
          {/* <StyledMenuPayment
            selected={window.location.pathname === '/dashboard/infocredit'}
            onClick={() => history.push('/dashboard/infocredit')}
            id="payment_platform"
          >
            <ListItemIcon />
            <Typography variant="inherit" className={useStyle().menuItemTypo}>
              Métodos de Pago
            </Typography>
          </StyledMenuPayment> */}
        </Hidden>
        <StyledMenuActivity
          selected={window.location.pathname === '/dashboard/activities'}
          onClick={() => history.push('/dashboard/activities')}
          id="activity"
        >
          <ListItemIcon />
          <Typography variant="inherit" className={useStyle().menuItemTypo}>
            Actividad
          </Typography>
        </StyledMenuActivity>

      </MenuList>
    </Box>
  );

  return (
    <Box
      component="div"
      style={{
        display: 'flex',
        height: '100vh',
      }}
    >
      <Grid container>
        <Hidden smDown>
          <Grid item md={2} style={{backgroundColor: '#144999'}}>
            {isActiveUser ? (
              drawerMenu
            ) : (
              limitedDrawer
            )}
          </Grid>
        </Hidden>
        <Grid className="p-3" item sm={12} md={10} xs={12} style={{backgroundColor: '#F3F3F3'}}>
          <Layout data={drawerMenu} toTutorial={handleTutorial}>
            {children}
          </Layout>
        </Grid>
      </Grid>
    </Box>
  );
};

export default PrincipalDashboard;
