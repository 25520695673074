import {useEffect} from 'react';

const useParticles = (containerId) => {
  useEffect(() => {
    const initParticles = () => {
      if (window.particlesJS && !window.pJSDom?.length) {
        window.particlesJS(containerId, {
          particles: {
            number: {
              value: 120,
              density: {
                enable: true,
                value_area: 1000,
              },
            },
            color: {
              value: '#ffffff',
            },
            shape: {
              type: 'circle',
            },
            opacity: {
              value: 0.5,
              random: false,
            },
            size: {
              value: 3,
              random: {
                enable: true,
                minimumValue: 1,
              },
            },
            line_linked: {
              enable: true,
              distance: 150,
              color: '#ffffff',
              opacity: 0.3,
              width: 1,
            },
            move: {
              enable: true,
              speed: 4,
            },
          },
          interactivity: {
            detect_on: 'canvas',
            events: {
              onhover: {
                enable: true,
                mode: 'repulse',
              },
              onclick: {
                enable: true,
                mode: 'push',
              },
              resize: true,
            },
          },
          retina_detect: true,
        });
      }
    };

    initParticles();

    return () => {
      // Solo destruimos si vamos a una ruta diferente a step2
      if (!window.location.pathname.includes('/ia/step2')) {
        if (window.pJSDom?.length) {
          window.pJSDom[0].pJS.fn.vendors.destroypJS();
          window.pJSDom = [];
        }
      }
    };
  }, [containerId]);
};

export default useParticles;
