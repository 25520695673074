import React, {useEffect, useState} from 'react';
import {Container, Grid} from '@material-ui/core';
import DialogFullScreen from '../../components/DialogFullScreen';
import DatabaseContainer from './DatabaseContainer';
import EditorJs from '../../components/EditorJs';

const OneDatabaseModal = ({
  open, handleClose, backoffice, doc, database, token,
}) => {
  const [fieldsLocal, setFieldsLocal] = useState([]);
  const [databaseRelation, setDatabaseRelation] = useState([]);
  const [fieldsRelation, setFieldsRelation] = useState([]);
  useEffect(() => {
    // adquiriendo los campos de la base de datos principal
    const fields = backoffice.fieldDatabases?.filter((field) => field.idDatabase === database._id);
    // obtener las base de datos que esta relacionadas con la base de datos principal
    const fieldsRelation2 = backoffice.fieldDatabases?.filter((field) => (
      field.relationDatabase === database._id
    ));
    const databaseRelation2 = backoffice.databases?.filter((databaseItem) => (
      fieldsRelation2.find((field) => field.idDatabase === databaseItem._id)
    ));
    setFieldsLocal(fields);
    setFieldsRelation(fieldsRelation2);
    setDatabaseRelation(databaseRelation2);
  }, [doc]);
  return (
    <DialogFullScreen open={open} handleClose={handleClose} title={database?.label}>
      <Container>
        <div className="mt-5">
          <div style={{
            backgroundColor: '#f0f0f0',
            padding: '10px',
            borderTopLeftRadius: '4px',
            borderTopRightRadius: '4px',
          }}
          >
            <h3 style={{
              margin: 0, textAlign: 'left', paddingLeft: '15px', color: '#292151',
            }}
            >
              <b>Información</b>
            </h3>
          </div>
          <div style={{
            border: '1px solid #ddd',
            borderRadius: '4px',
            marginTop: '0',
          }}
          >
            <Grid container>
              {fieldsLocal.map((field, index) => (
                <Grid item xs={6} key={field._id}>
                  <div style={{
                    padding: '15px',
                    borderBottom: '1px solid #eee',
                    borderRight: index % 2 === 0 ? '1px solid #eee' : 'none',
                    // eslint-disable-next-line comma-dangle
                    height: '100%'
                  }}
                  >
                    <div style={{
                      color: '#333',
                      marginBottom: '5px',
                      fontWeight: 'bold',
                    }}
                    >
                      {field.label}
                      :
                    </div>
                    <div style={{color: '#333'}}>
                      {field.type === 'textrich' ? (
                        <EditorJs
                          backoffice
                          idProject={backoffice.project._id}
                          defaultValue={JSON.parse(doc[field.name])}
                        />
                      ) : typeof doc[field.name] === 'boolean' ? (
                        <span>{doc[field.name] ? 'Sí' : 'No'}</span>
                      ) : (
                        <span>{doc[field.name] || '-'}</span>
                      )}
                    </div>
                  </div>
                </Grid>
              ))}
            </Grid>
          </div>
        </div>
        <div className="mt-4">
          <h3 className="text-center">
            <b>
              Relaciones
            </b>
          </h3>
          <div className="mt-4">
            {
              databaseRelation.map((databaseItem) => {
                const field = fieldsRelation.find((field2) => (
                  field2.idDatabase === databaseItem._id
                ));
                if (field) {
                  return (
                    <div key={databaseItem._id} className="mt-4">
                      <div className="mt-4">
                        <DatabaseContainer
                          backoffice={backoffice}
                          database={databaseItem}
                          fieldName={field.name}
                          idDoc={doc._id}
                          token={token}
                          type="filter"
                          id
                        />
                      </div>
                    </div>
                  );
                }
                return <span key={databaseItem._id} />;
              })
            }
          </div>
        </div>
      </Container>
    </DialogFullScreen>
  );
};

export default OneDatabaseModal;
