import React, {useEffect, useState} from 'react';
import {
  Box, Typography, Button, Grid,
} from '@mui/material';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {getLoggedUser} from 'api-lofty';
import IALayout from '../layouts/IALayout';
import UserIcon from '../assets/ia/User.png';

const ProjectCard = ({
  title, description, onClick,
}) => (
  <Box
    sx={{
      background: '#F5F5F5',
      padding: {xs: '1.5rem', sm: '1.5rem', md: '2rem'},
      display: 'flex',
      flexDirection: 'column',
      gap: {xs: '1.5rem', sm: '1.5rem', md: '2rem'},
      height: '100%',
      minHeight: {xs: '220px', sm: '250px', md: '280px'},
      borderRadius: '8px',
    }}
  >
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <Typography
        variant="h6"
        sx={{
          fontSize: {xs: '0.875rem', sm: '0.875rem', md: '1rem'},
        }}
      >
        GENERAR
      </Typography>
      <Box
        sx={{
          background: '#00A3FF',
          padding: '4px 8px',
          color: 'white',
          fontSize: {xs: '0.75rem', sm: '0.75rem', md: '0.875rem'},
        }}
      >
        AI
      </Box>
    </Box>

    <Box>
      <Typography
        variant="h5"
        sx={{
          fontWeight: 'bold',
          mb: 2,
          fontSize: {xs: '1.125rem', sm: '1.125rem', md: '1.25rem'},
        }}
      >
        {title}
      </Typography>
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{
          fontSize: {xs: '0.875rem', sm: '0.875rem', md: '1rem'},
        }}
      >
        {description}
      </Typography>
    </Box>

    <Button
      variant="contained"
      sx={{
        background: '#1A1147',
        mt: 'auto',
        py: {xs: 1, sm: 1, md: 1.5},
        color: '#FFFFFF',
        fontSize: {xs: '0.875rem', sm: '0.875rem', md: '1rem'},
        '&:hover': {
          background: '#2A1F5F',
        },
      }}
      onClick={onClick}
    >
      Generar
    </Button>
  </Box>
);

const IAProjectSelection = ({history, setProjectData, token}) => {
  const [userName, setUserName] = useState('Usuario');

  useEffect(() => {
    getLoggedUser({token}).then((res) => {
      setUserName(res.user.firstName || res.user.email);
    });
  }, [token]);

  const handleSelection = (pageCount) => {
    setProjectData({
      pageCount,
    });
    history.push('/ia/step1');
  };

  return (
    <IALayout>
      <Box
        sx={{
          position: 'absolute',
          top: {xs: '1rem', sm: '1.5rem', md: '2rem'},
          right: {xs: '1rem', sm: '1.5rem', md: '2rem'},
          display: 'flex',
          alignItems: 'center',
          gap: 1,
        }}
      >
        <Box
          component="img"
          src={UserIcon}
          alt="User"
          sx={{
            width: {xs: '20px', sm: '22px', md: '24px'},
            height: {xs: '20px', sm: '22px', md: '24px'},
            filter: {xs: 'none', md: 'brightness(0)'},
          }}
        />
        <Typography
          variant="body1"
          sx={{
            fontSize: {xs: '0.875rem', sm: '0.875rem', md: '1rem'},
          }}
        >
          Hola,
          {' '}
          {userName}
          !
        </Typography>
      </Box>
      <Box
        sx={{
          mt: {xs: 4, sm: 6, md: 8},
          px: {xs: 2, sm: 3, md: 4},
          width: '100%',
          maxWidth: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Box sx={{textAlign: 'center', mb: {xs: 4, sm: 5, md: 6}, maxWidth: '90%'}}>
          <Typography
            variant="h4"
            sx={{
              mb: 1,
              color: '#201549',
              fontSize: {xs: '1.5rem', sm: '1.75rem', md: '2rem'},
              fontWeight: 500,
            }}
          >
            Que deseas hacer?
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              color: '#201549',
              fontSize: {xs: '0.875rem', sm: '0.875rem', md: '1rem'},
            }}
          >
            Sigue los siguientes pasos
          </Typography>
        </Box>

        <Grid
          container
          spacing={3}
          sx={{
            maxWidth: {xs: '100%', sm: '100%', md: '900px'},
            mx: 'auto',
          }}
        >
          <Grid item xs={12} sm={12} md={6}>
            <ProjectCard
              title="CREAR NUEVO"
              description="Un sitio de hasta 3 páginas informativas."
              onClick={() => handleSelection(3)}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6}>
            <ProjectCard
              title="CREAR NUEVO"
              description="Quiero crear una tienda online hasta 6 páginas."
              onClick={() => handleSelection(6)}
            />
          </Grid>
        </Grid>
      </Box>
    </IALayout>
  );
};

const mapStateToProps = (state) => ({
  ...state.auth,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectData: (data) => dispatch({type: 'SET_PROJECT_DATA', payload: data}),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IAProjectSelection));
