/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Box,
  Typography,
  IconButton,
  TextField,
  Menu,
  MenuItem,
  Checkbox,
  Button,
  Grid,
} from '@material-ui/core';
import CheckIcon from '@material-ui/icons/Check';
import React, {useState, useEffect, useRef} from 'react';
import {connect} from 'react-redux';
import {makeStyles} from '@material-ui/core/styles';
import toastr from 'toastr';
import PlusOneIcon from '@material-ui/icons/Add';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {
  listDatabases, getOneProject, listFieldsDatabase, getOneDatabase,
  createEndpoint,
  listEndpoint,
  isInvalidSlug,
} from 'api-lofty';
import Blockly from 'blockly';
import Layout from '../layouts/DatabaseDashboard';
import LoadingPage from './LoadingPage';
// import {listDatabases} from '../api/database.api';
// import {getOneProject} from '../api/project.api';
import 'toastr/build/toastr.min.css';
import defineDBBlocks, {addNewParamBlockBlockly, addNewVariableWithFieldData, addUserAuthBlockBlockly} from '../util/customServicesBlockDefinitions';
import AddParamModal from '../components/AddParamModal';
import blocklyAnalyzer from '../util/blocklyAnalyzer';
import {blocklyVariableSeeker} from '../util/blocklyFunctionsSeeker';
import {generateSlugSampleProject} from '../util/generateSuggestionSlug';

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
  },
  textButton: {
    fontSize: '20px',
    [theme.breakpoints.down('lg')]: {fontSize: '14px'},
    [theme.breakpoints.down('md')]: {fontSize: '12px'},
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    marginLeft: '10px',
    marginBottom: '15px',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  mainContainer: {
    paddingLeft: 15,
    marginTop: 20,
  },
  formSection: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
  },
  blocklySection: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '8px',
  },
  fullWidthInput: {
    width: '100%',
    marginBottom: '20px',
  },
  urlSection: {
    marginTop: '20px',
    marginBottom: '20px',
  },
  paramsSection: {
    marginTop: '20px',
  },
  userAuthSection: {
    marginTop: '20px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
  },
  toolboxSection: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '200px',
    height: '100%',
    backgroundColor: '#f5f5f5',
    borderRight: '1px solid #ddd',
  },
}));

const ParametersTable = ({parameters, onDelete}) => {
  if (!parameters || parameters.length === 0) {
    return null; // Retorna null si no hay parámetros
  }

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Etiqueta</TableCell>
            <TableCell>Slug</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell align="center">Requerido</TableCell>
            <TableCell align="center">Acciones</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {parameters.map((param, index) => (
            <TableRow key={param.name || index}>
              <TableCell>{param.label}</TableCell>
              <TableCell>{param.name}</TableCell>
              <TableCell>{param.type}</TableCell>
              <TableCell align="center">
                {param.required && <CheckIcon style={{color: '#29B2EF'}} />}
              </TableCell>
              <TableCell align="center">
                <IconButton onClick={() => onDelete(index)}>
                  <DeleteOutlineOutlinedIcon style={{color: 'red'}} />
                </IconButton>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
const CustomServices = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [listAllDatabases, setListAllDatabases] = useState([]);
  const [dataFields, setDataFields] = useState([]);
  const [listEndpointData, setListEndpointData] = useState([]);
  const [project, setProject] = useState();
  const {params} = match;
  const [counts, setCounts] = React.useState(0);
  const {idProject, idDatabase} = params;
  const [labelUrl, setLabelUrl] = useState('');
  const [database, setDatabase] = useState();
  const [urlInfo, setUrlInfo] = React.useState([]);
  const [isWithUserAuth, setIsWithUserAuth] = useState(false);
  /* const [urlLabel, setUrlLabel] = React.useState(''); */
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [toolboxReact, setToolboxReact] = React.useState([]);
  const [openAdd, setOpenAdd] = React.useState(false);
  // blockly
  const blocklyRef = useRef();
  let blocklyWorkspace = null;
  const [renderHelper, setRenderHelper] = useState(0);
  const [toolbox, setToolbox] = useState();
  const [blocklyWorkspaceReact, setBlocklyWorkspaceReact] = useState();
  const [variableInfoData, setVariableInfoData] = useState([]); // Add state for variables
  // end blockly

  const [isEditingLabel, setIsEditingLabel] = useState(false);
  const [suggestionSlug, setSuggestionSlug] = useState('');
  const [errorInSlug, setErrorInSlug] = useState(false);
  const [errorMessageSlug, setErrorMessageSlug] = useState('');

  const [typeOfFunction, setTypeOfFunction] = useState('');

  function generateSuggestionSlug(value) {
    if (isEditingLabel) {
      const res = generateSlugSampleProject(listEndpointData, value);
      setSuggestionSlug(res);
      setIsEditingLabel(false);
    }
  }

  const verifySlugValue = (value) => {
    if (isInvalidSlug(value)) {
      setErrorMessageSlug('Verifique que no tenga espacios/palabras reservadas');
      setErrorInSlug(true);
    } else {
      setErrorInSlug(false);
      setErrorMessageSlug('');
    }
  };

  const classes = useStyle();
  const handleOpenMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };

  const handleItemSelection = (value) => {
    if (value === 'route') {
      const toAdd = {idNumber: counts, preceding: '/', value: ''};
      const temp = urlInfo;
      temp.push(toAdd);
      setUrlInfo(temp);
      setCounts(counts + 1);
      setAnchorEl(null);
    }
  };
  const updateValueData = (index) => (e) => {
    const tempArray = [...urlInfo];
    tempArray[index].value = e.target.value;
    setUrlInfo(tempArray);
  };
  const eliminateValueFrom = (location) => {
    setUrlInfo((urlInfo) => urlInfo.filter((index) => index.idNumber !== location));
  };

  const getCollectionFields = async () => {
    // const collectionXfields = [];
    const resDatabases = await listDatabases({token, idProject});
    // eslint-disable-next-line consistent-return
    // const asyncFunctions = [];
    // const asyncFunctions = resDatabases.data.((collection) => async () => {
    // const fields = await listFieldsDatabase({token, idDatabase: collection._id, idProject});
    // if (fields?.data.length > 0) {
    //   const mappedFields = fields
    //     ?.data?.map?.((field) => ({_id: field._id, label: field.label}));
    //   return {collection: {id: collection.name, label: collection.label}, fields: mappedFields};
    // }
    // });
    const collectionData = [];
    setListAllDatabases(resDatabases.data);
    for (const collection of resDatabases.data) {
      const fields = await listFieldsDatabase({token, idDatabase: collection._id, idProject});
      if (fields?.data.length > 0) {
        const mappedFields = fields
          ?.data?.map?.((field) => ({_id: field._id, label: field.label}));
        if (collection?.isBucket) {
          mappedFields.push({_id: 'loftyFile', label: 'Archivo'});
        }
        collectionData.push(
          {collection: {id: collection.name, label: collection.label}, fields: mappedFields},
        );
      }
    }
    setDataFields(collectionData);
  };

  const handleListData = async () => {
    try {
      const resProject = await getOneProject({token, idProject});
      const resDatabases = await listDatabases({token, idProject});
      const resOneDatabase = await getOneDatabase({_id: idDatabase, token});
      const resAllEndpoints = await listEndpoint({idDatabase, token});
      setLabelUrl(`proyectos/${resProject.project.name}/Colección de Datos/${resOneDatabase.data.label}/Servicios Personalizados/`);
      setProject(resProject.project);
      const dataCollection = [];
      for (const collection of resDatabases.data) {
        const fields = await listFieldsDatabase({token, idDatabase: collection._id, idProject});
        if (fields?.data.length > 0) {
          dataCollection.push(collection);
        }
      }
      setData(dataCollection);
      setDatabase(resOneDatabase.data);
      setListEndpointData(resAllEndpoints.data);
      setVariableInfoData(resAllEndpoints.data.logicInfo.variables); // Set variables
    } catch (error) {
      // error
    }
  };

  let serialization;

  // eslint-disable-next-line no-unused-vars
  const handleLoad = async () => {
    const mainWorkspace = Blockly.getMainWorkspace();
    const serializer = new Blockly.serialization.blocks.BlockSerializer();
    serializer.load(serialization, mainWorkspace);
  };

  const handleSubmitAdd = async (e) => {
    e.preventDefault();
    const mainWorkspace = Blockly.getMainWorkspace();

    // Blocks
    const serializerBlocks = new Blockly.serialization.blocks.BlockSerializer();
    const stateBlocks = serializerBlocks.save(mainWorkspace);
    serialization = stateBlocks;

    // Variables
    const serializeVars = new Blockly.serialization.variables.VariableSerializer();
    const stateVars = serializeVars.save(mainWorkspace);

    try {
      // Check Before Sending
      const blockInfo = stateBlocks.blocks?.find((index) => (index.type === 'main_block'));
      const searchVars = blocklyVariableSeeker(blockInfo, listAllDatabases, dataFields, [], [], []);
      const infoLink = blocklyAnalyzer(blockInfo, listAllDatabases, dataFields, [], searchVars);
      if (infoLink?.status === 'error') {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${infoLink.info}`), 300);
      } else {
        await createEndpoint({
          token,
          idDatabase,
          idProject,
          isAuthentication: isWithUserAuth,
          label: e.target.label.value,
          slug: suggestionSlug,
          typeFunction: e.target.typeFunction.value,
          url: urlInfo.map((urlEl) => (`${urlEl.preceding}${urlEl.value}`)).join(''),
          arrayParamsEndpoint: toolboxReact,
          logicEndpoint: {
            arrayLogic: stateBlocks,
            createdAt: new Date(),
            variables: stateVars,
            version: '1.0',
          },
        });
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.success('Se agregó el Servicio Personalizado'), 300);
        handleListData();
        history.push(`/dashboard/project/${idProject}/database/${idDatabase}/endpoints`);
      }
    } catch (error) {
      // console.log(error);
      if (error.info) {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error(`error: \n ${error.info}`), 300);
      } else {
        toastr.options = {
          positionClass: 'toast-top-right',
          hideDuration: 300,
          timeOut: 6000,
        };
        toastr.clear();
        setTimeout(() => toastr.error('Lo sentimos no se pudo agregar el Servicio Personalizado'), 300);
      }
    }
  };

  const handleAddParam = async (e, required, arrayOfDefaults, selectedRelation, selectedEndpoint) => {
    e.preventDefault();
    const newBlock = {
      type: e.target.type.value,
      label: e.target.label.value,
      name: e.target.name.value,
      typeParam: e.target?.typeParam?.value || null,
      required,
      stringDefaultValues: arrayOfDefaults,
      relationDatabase: selectedRelation || null,
      relationEndpoint: selectedEndpoint || null,
    };
    addNewParamBlockBlockly(
      blocklyWorkspaceReact,
      toolbox,
      setToolbox,
      setOpenAdd,
      newBlock.name,
      newBlock.label,
      newBlock.type,
      toolboxReact,
    );
    setToolboxReact(((oldTBReact) => ([...oldTBReact, newBlock])));
  };

  const handleAddVariableWithFields = (variables, stateVars, workspace) => {
    try {
      if (variables?.length > 0) {
        addNewVariableWithFieldData(
          workspace,
          toolbox,
          setToolbox,
          variables,
          stateVars,
        );
      } else {
        addNewVariableWithFieldData(
          workspace,
          toolbox,
          setToolbox,
          [],
          stateVars,
        );
      }
    } catch (error) {
      // console.log(error);
    }
  };

  const handleDeleteParam = (index) => {
    const newParams = toolboxReact.filter((_, i) => i !== index);
    setToolboxReact(newParams);
  };
  const handleHasUserAuthentication = async () => {
    // const newBlock = {
    //   type: 'with_user_auth',
    //   label: 'Usar Usuario Autenticado',
    //   name: 'use_current_auth_user',
    // };
    addUserAuthBlockBlockly(
      blocklyWorkspaceReact,
      toolbox,
      setToolbox,
      !isWithUserAuth,
    );
    setIsWithUserAuth(!isWithUserAuth);
    // setToolboxReact(((oldTBReact) => ([...oldTBReact, newBlock])));
  };

  function handleUpdateBlockly(event) {
    const mainWorkspace = Blockly.getMainWorkspace();
    // const xml = Blockly.Xml.workspaceToDom(blocklyWorkspace);
    // Blocks
    const serializerBlocks = new Blockly.serialization.blocks.BlockSerializer();
    const stateBlocks = serializerBlocks.save(mainWorkspace);
    serialization = stateBlocks;

    // Variables
    const serializeVars = new Blockly.serialization.variables.VariableSerializer();
    const stateVars = serializeVars.save(mainWorkspace);
    const blockInfo = stateBlocks?.blocks?.find((index) => (index.type === 'main_block'));
    if (blockInfo && event) {
      const searchVars = blocklyVariableSeeker(blockInfo, listAllDatabases, dataFields, [], [], []);
      handleAddVariableWithFields(searchVars, stateVars, mainWorkspace);
    }
  }

  useEffect(() => {
    if (!blocklyRef.current) {
      setRenderHelper(renderHelper + 1);
      return;
    }

    const toolbox1 = defineDBBlocks({
      collections: data,
      fields: dataFields,
      parameters: toolboxReact,
      variables: variableInfoData, // Ensure variables are passed correctly
    });

    blocklyWorkspace = Blockly.inject('blocklyDiv', {toolbox: toolbox1, maxInstances: {main_block: 1}});
    blocklyWorkspace.addChangeListener(handleUpdateBlockly);
    setBlocklyWorkspaceReact(blocklyWorkspace);
    setToolbox(toolbox1);

    // Define blankDiv before using it
    const blankDiv = document.querySelector('.injectionDiv');
    if (blankDiv) {
      blankDiv.style.display = 'none';
    }
  }, [blocklyRef.current, renderHelper]);

  useEffect(() => {
    const loadAll = async () => {
      await handleListData();
      await getCollectionFields();
      setLoading(false);
    };
    loadAll();
  }, []);

  if (loading) {
    return <LoadingPage />;
  }

  return (
    <Layout
      databaseName={database?.label}
      history={history}
      idProject={idProject}
      projectName={project?.name}
      idDatabase={idDatabase}
      token={token}
    >
      <Box>
        <AddParamModal
          open={openAdd}
          handleClose={() => setOpenAdd(false)}
          handleForm={handleAddParam}
          typeEndpoint={typeOfFunction}
          idProject={idProject}
          token={token}
        />
      </Box>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {labelUrl}
      </Typography>
      <Box
        component="div"
        sx={{
          backgroundColor: '#fff', width: '100%', height: '60px', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1, borderRadius: '8px',
        }}
      >
        <Box component="div" sx={{display: 'flex', alignItems: 'center'}}>
          <IconButton onClick={() => history.push(`/dashboard/project/${idProject}/database/${idDatabase}/endpoints`)}>
            <ArrowBackIcon style={{fontSize: '28px', color: 'black'}} />
          </IconButton>
          <Typography className={classes.miniNavbarMenuTitle}>
            Servicios Personalizados
          </Typography>
        </Box>
        <Button
          variant="contained"
          color="primary"
          size="medium"
          className={classes.textButton}
          style={{
            color: 'black',
            fontWeight: 'bold',
            backgroundColor: '#29B2EF',
            fontFamily: [
              'Nunito',
              'Roboto',
              'Helvetica Neue',
              'Arial',
              'sans-serif',
            ].join(','),
            textTransform: 'none',
          }}
          startIcon={<PlusOneIcon />}
          type="submit"
          form="saveform"
        >
          Guardar Cambios
        </Button>
      </Box>

      <Grid spacing={2} container className={classes.mainContainer}>
        <Grid md={3} className={classes.formSection}>
          <form id="saveform" onSubmit={handleSubmitAdd}>
            <TextField
              className={classes.fullWidthInput}
              name="label"
              label="Etiqueta"
              variant="outlined"
              onFocus={() => setIsEditingLabel(true)}
              onBlur={(e) => generateSuggestionSlug(e.target.value)}
            />

            <TextField
              className={classes.fullWidthInput}
              name="slug"
              label="Slug"
              variant="outlined"
              value={suggestionSlug}
              onChange={(e) => setSuggestionSlug(e.target.value)}
              helperText={errorMessageSlug}
              error={errorInSlug}
              onBlur={(e) => verifySlugValue(e.target.value)}
            />

            <TextField
              className={classes.fullWidthInput}
              name="typeFunction"
              label="Tipo"
              variant="outlined"
              onChange={(e) => setTypeOfFunction(e.target.value)}
              select
            >
              <MenuItem value="customCreate">Crear</MenuItem>
              <MenuItem value="customList">Obtener una lista</MenuItem>
              <MenuItem value="customOne">Obtener un dato</MenuItem>
              <MenuItem value="customDelete">Eliminar un dato</MenuItem>
              <MenuItem value="customUpdate">Actualizar un dato</MenuItem>
            </TextField>

            <Box className={classes.urlSection}>
              <Typography variant="subtitle1">URL:</Typography>
              <Box
                sx={{
                  display: 'flex',
                  flexWrap: 'wrap',
                  gap: '10px',
                  alignItems: 'center',
                }}
              >
                {urlInfo.map((index, key) => (
                  <Box key={index.idNumber} sx={{display: 'flex', alignItems: 'center'}}>
                    <Typography>{index.preceding}</Typography>
                    <TextField
                      size="small"
                      variant="outlined"
                      value={index.value}
                      onChange={updateValueData(key)}
                    />
                    <IconButton onClick={() => eliminateValueFrom(index.idNumber)}>
                      <DeleteOutlineOutlinedIcon />
                    </IconButton>
                  </Box>
                ))}
                <IconButton onClick={handleOpenMenu}>
                  <PlusOneIcon style={{color: 'rgba(41, 33, 81, 1)'}} />
                </IconButton>
                <Menu
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleCloseMenu}
                >
                  <MenuItem onClick={() => handleItemSelection('route')}>Ruta</MenuItem>
                </Menu>
              </Box>
            </Box>

            <Box className={classes.userAuthSection}>
              <Typography>Usar usuario autenticado:</Typography>
              <Checkbox
                checked={isWithUserAuth}
                onChange={handleHasUserAuthentication}
              />
            </Box>

            <Box className={classes.paramsSection}>
              <Box sx={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                <Typography variant="subtitle1">Parámetros:</Typography>
                <IconButton onClick={() => setOpenAdd(true)}>
                  <PlusOneIcon />
                </IconButton>
              </Box>
              <ParametersTable parameters={toolboxReact} onDelete={handleDeleteParam} />
            </Box>
          </form>
        </Grid>

        {/* Blockly Section */}
        <Grid md={9} className={classes.blocklySection}>
          <div
            ref={blocklyRef}
            id="blocklyDiv"
            style={{
              height: '600px',
              width: '100%',
              position: 'relative',
            }}
          />
        </Grid>
      </Grid>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(CustomServices);
