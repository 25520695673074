// routesjs
import React, {useState, useEffect} from 'react';
import {getApiRestBackoffice, getOneProject} from 'api-lofty';
import {connect} from 'react-redux';
// import {getLoggedUser} from './api/user.api';
import {addDataBackoffice} from '../actions/backoffice.actions';
// Pages
import LoadingPage from '../pages/LoadingPage';
import Error404 from '../pages/Error404';
import DashboardPage from './pages/DashboardPage';
import DatabasePage from './pages/DatabasePage';
import UserPage from './pages/UserPage';

const App = ({
  token, addDataBackofficeApp, match,
  history,
}) => {
  // props
  const {params} = match;
  const {idProject, database} = params;
  // estados
  const [loading, setLoading] = useState(true);
  const [dataBackoffice, setDataBackoffice] = useState();
  const [haveError, setError] = useState(false);
  const [dataDatabase, setDataDatabase] = useState();
  const handleToken = async () => {
    try {
      const dataProject = await getOneProject({
        idProject, token,
      });
      const {project} = dataProject;
      const {slug} = project;
      if (!token) {
        history.push('/');
      } else {
        const resBackoffice = await getApiRestBackoffice({
          slug, token,
        });
        if (resBackoffice.data) {
          // se agregaron los datos del backoffice
          addDataBackofficeApp(resBackoffice.data);
          setDataBackoffice(resBackoffice.data);
        }
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setError(true);
    }
    setLoading(false);
  };
  useEffect(() => {
    handleToken();
  }, []);
  useEffect(() => {
    if (database && dataBackoffice) {
      if (database !== 'user') {
        const infoDatabase = dataBackoffice.databases?.find(({name}) => name === database);
        setDataDatabase(infoDatabase);
      }
    }
  }, [database, dataBackoffice]);
  if (haveError) {
    return <Error404 />;
  }
  if (loading) {
    return <LoadingPage />;
  }
  return (
    <>
      {/* Agregando las paginas correspondients del backoffice */}
      {
        dataBackoffice && (
          <>
            {
              !database ? (
                <DashboardPage token={token} history={history} idProject={idProject} />
              ) : <></>
            }
            {
              (dataDatabase && database !== 'user') ? (
                <DatabasePage history={history} idProject={idProject} database={dataDatabase} />
              ) : <></>
            }
            {
              (database === 'user') ? (
                <UserPage idProject={idProject} history={history} />
              ) : <></>
            }
          </>
        )
      }
      {/* Pagina de 404 */}
      {/* <Route path="*" element={<Error404 />} /> */}
    </>
  );
};

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addDataBackofficeApp: (data) => dispatch(addDataBackoffice(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);
