/* eslint-disable no-unused-vars */
/* eslint-disable no-alert */
import React, {useState, useEffect} from 'react';
import {connect} from 'react-redux';
import Button from '@material-ui/core/Button';
import {makeStyles} from '@material-ui/core/styles';
import {Box, Typography} from '@material-ui/core';
import NotInterestedIcon from '@material-ui/icons/NotInterested';
// import PlusOneIcon from '@material-ui/icons/Add';
import Grid from '@material-ui/core/Grid';
import {
  listDatabases, getOneProject, getSizeStorageDeployment, listDomainProject,
  createDomainController,
} from 'api-lofty';
import {
  Chart as ChartJS, ArcElement, Tooltip, Legend,
} from 'chart.js';
import toastr from 'toastr';
import {Pie, Doughnut} from 'react-chartjs-2';
import {updateDeploymentProject} from 'api-lofty/lib/api/deploymentRequest.api';
import Layout from '../layouts/ProjectDashboard';
import LoadingPage from './LoadingPage';
import HistoryProjectDashboard from '../components/HistoryProjectDashboard';
import ProjectDataCard from '../components/ProjectDataCard';
import deployImage from '../img/deploy/deploy.png';
import storageImage from '../img/deploy/Storage.png';
import updateImage from '../img/deploy/deploy_update.png';
import icon1Image from '../img/deploy/User_Account.png';
import icon2Image from '../img/deploy/Meeting_Room.png';
import icon3Image from '../img/deploy/Database_Icon1.png';
import DomainAddDialog from '../components/DomainAddDialog';

ChartJS.register(ArcElement, Tooltip, Legend);

const useStyle = makeStyles((theme) => ({
  miniNavbarMenuTitle: {
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    fontSize: '21px',
    fontWeight: 'bold',
  },
  contentAlign: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      justifyContent: 'space-around',
    },
  },
  historyDivContainer: {
    height: '400px',
    backgroundColor: '#fff',
    width: '100%',
    padding: '10px',
    overflowY: 'scroll',
  },
  historyTileSection: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '95%',
  },
  title1HistorySection: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  title2HistorySection: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  title1ChartSection: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '19px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  cardDataSection: {
    height: 'auto',
    display: 'block',
    justifyContent: 'space-between',
    width: '100%',
    // overflowY: 'scroll',
    overflowX: 'clip',
  },
  doughnutSection: {
    width: '85%',
    padding: '20px',
    margin: '10px',
    backgroundColor: '#fff',
  },
  doughnutTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
    marginBottom: '10px',
  },
  title1DoughnutSection: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '21px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  chartsSection: {
    display: 'flex',
    width: '95%',
  },
  chartPie: {
    margin: '20px',
    width: '45%',
    padding: '15px',
    backgroundColor: '#fff',
  },
  optionsSection: {
    margin: '20px',
    position: 'relative',
    width: '50%',
    padding: '15px',
    height: '400px',
  },
  deployArea: {
    width: '100%',
    height: '40%',
    padding: '15px',
    backgroundColor: '#fff',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  deployArea2: {
    width: '100%',
    height: '60%',
    padding: '15px',
    marginTop: '10px',
    backgroundColor: '#fff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  descriptionHistorySection: {
    display: 'flex',
    flexDirection: 'row',
    padding: '5px',
    backgroundColor: '#fff',
    position: 'relative',
    width: '95%',
    height: 'auto',
    borderTop: '1px solid',
    borderBottom: '1px solid',
    borderColor: 'rgba(166, 206, 227, 1)',
  },
  content: {
    flex: 1,
  },
  descriptionSegment: {
    width: '60%',
    padding: '10px',
  },
  userSegment: {
    width: '20%',
    display: 'flex',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
  },
  timeDateSegment: {
    width: '20%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignContent: 'center',
    alignItems: 'center',
  },
  descriptionText: {
    color: 'rgba(166, 206, 227, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  userText: {
    color: 'rgba(166, 206, 227, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  dateText: {
    color: 'rgba(166, 206, 227, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  timeText: {
    color: 'rgba(166, 206, 227, 1)',
    fontSize: '16px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '12px'},
    [theme.breakpoints.down('md')]: {fontSize: '10px'},
    [theme.breakpoints.down('xs')]: {fontSize: '14px'},
  },
  mainTitle: {
    color: 'rgba(0, 0, 0, 1)',
    fontSize: '22px',
    fontWeight: 'bold',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '17px'},
    [theme.breakpoints.down('md')]: {fontSize: '14px'},
    [theme.breakpoints.down('xs')]: {fontSize: '18px'},
  },
  doughnutInnerSectionText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '20px',
  },
  doughnutArea: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignItems: 'center',
    position: 'relative',
  },
  doughnutInnerArea: {
    display: 'flex',
    width: '100%',
    justifyContent: 'center',
  },
  doughnutInnerText: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '40px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
  },
  doughnutInnerText2: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '24px',
    fontFamily: [
      'Nunito',
      'Roboto',
      'Helvetica Neue',
      'Arial',
      'sans-serif',
    ].join(','),
    [theme.breakpoints.down('lg')]: {fontSize: '20px'},
    [theme.breakpoints.down('md')]: {fontSize: '16px'},
    [theme.breakpoints.down('xs')]: {fontSize: '22px'},
  },
  textDomainArea: {
    color: 'rgba(41, 33, 81, 1)',
    fontSize: '21px',
    fontWeight: 700,
  },
  domainTextStyle: {
    color: 'rgba(110, 122, 144, 1)',
    fontSize: '21px',
    fontWeight: 400,
  },
}));

const DeployDashboard = ({history, match, token}) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState([]);
  const [project, setProject] = useState();
  const [onUseStorage, setOnUseStorage] = useState(0);
  const [usePercentage, setUsePercentage] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [domainSite, setDomainSite] = useState();
  const [openDomainAddDialog, setOpenDomainAddDialog] = useState(false);
  const [testerData3, setTesterData3] = useState([]);
  const [domainVerify, setDomainVerify] = useState([]);
  const [testerData1] = useState([]); // FOR NULL TESTING
  // const [testerData2] = useState([]); // FOR NULL TESTING
  const {params} = match;
  const {idProject} = params;
  const classes = useStyle();

  const testerData2 = [
    {
      title: 'cantidad de Colaboradores',
      quantity: 2,
      color: 'rgba(41, 33, 81, 1)',
      icon: icon1Image,
    },
    {
      title: 'cantidad de Usuarios de prueba',
      quantity: 10,
      color: 'rgba(41, 178, 239, 1)',
      icon: icon2Image,
    },
    {
      title: 'cantidad de DB Creadas',
      quantity: 4,
      color: 'rgba(166, 206, 227, 1)',
      icon: icon3Image,
    },
  ];

  const chartdata = {
    labels: ['DB1', 'DB2', 'DB3', 'DB4', 'DB5', 'DB6'],
    datasets: [
      {
        label: '# of Data',
        data: [12, 19, 3, 5, 2, 3],
        backgroundColor: [
          'rgba(166, 206, 227, 0.7)',
          'rgba(178, 223, 138, 0.7)',
          'rgba(41, 178, 239, 0.7)',
          'rgba(142, 48, 255, 0.7)',
          'rgba(20, 73, 153, 0.7)',
          'rgba(255, 159, 64, 0.7)',
        ],
        borderColor: [
          'rgba(166, 206, 227, 1)',
          'rgba(178, 223, 138, 1)',
          'rgba(41, 178, 239, 1)',
          'rgba(142, 48, 255, 1)',
          'rgba(20, 73, 153, 1)',
          'rgba(255, 159, 64, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const doughnutdata = {
    labels: ['En Uso (GB)', 'Disponible (GB) '],
    datasets: [
      {
        label: 'Almacenamiento',
        data: [onUseStorage, (totalStorage - onUseStorage)],
        backgroundColor: [
          'rgba(20, 73, 153, 0.7)',
          'rgba(217, 217, 217, 0.7)',
        ],
        borderColor: [
          'rgba(20, 73, 153, 1)',
          'rgba(217, 217, 217, 1)',
        ],
        borderWidth: 1,
      },
    ],
  };

  const getData = async () => {
    try {
      /*
      FOR TESTING ONLY

      setOnUseStorage(2.7);
      setTotalStorage(5);
      const totalPercent = (2.7 / 5) * 100;
      setUsePercentage(totalPercent);

      setDomainSite('www.helloworld.net');

      */
      const getProject = await getOneProject({token, idProject});
      setProject(getProject.project);
      const resDomains = await listDomainProject({
        idProject, token,
      });
      const getFullResData = resDomains.data.dataDomain.map((index) => {
        const searchForIP = resDomains.data.dataServerProxy.find(
          (value) => (value._id === index.idServerProxy),
        );
        return ({
          idDomain: index._id,
          typeRegister: index.validDomain,
          ipdomain: searchForIP?.ip,
          domainsite: index.domain,
        });
      });
      setDomainVerify(getFullResData);
      setTesterData3(resDomains.data);
      const getDatabases = await listDatabases({token, idProject});
      setData(getDatabases.data);
      const getStorageData = await getSizeStorageDeployment({token, idProject});
      setOnUseStorage(getStorageData.data.storage / 1000);
      setTotalStorage(getStorageData.data.total / 1000);
      const totalPercent = (getStorageData.data.storage / getStorageData.data.total) * 100;
      setUsePercentage(totalPercent);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  useEffect(() => {
    getData();
  }, []);

  async function handleAddDomain(stateData) {
    const refreshDomains = await listDomainProject({idProject, token});
    setTesterData3(refreshDomains.data);
  }

  if (loading) {
    return <LoadingPage />;
  }
  return (
    <Layout history={history} idProject={idProject} projectName={project?.name}>
      <Typography component="h5" variant="h5" className={classes.mainTitle}>
        {`/proyectos/${project.name}`}
      </Typography>
      <DomainAddDialog
        open={openDomainAddDialog}
        handleClose={() => setOpenDomainAddDialog(false)}
        handleForm={(data) => handleAddDomain(data)}
        dataInfo={testerData3}
        token={token}
        idProject={idProject}
        infoVerify={domainVerify}
      />
      <Box
        component="div"
        sx={{
          width: '100%', height: '100%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', p: 1, m: 1,
        }}
      >
        <Grid container className="mt-6">
          <Grid item md={4}>
            <Box component="div" className={classes.cardDataSection}>
              {testerData2.length !== 0
                ? (
                  <Box>
                    {testerData2.map((value) => (
                      <ProjectDataCard
                        title={value.title}
                        quantity={value.quantity}
                        color={value.color}
                        icon={value.icon}
                      />
                    ))}
                  </Box>
                )
                : (
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <Typography component="h5" variant="h5">
                      No Hay Informacion
                    </Typography>
                    <Box component="img" src={deployImage} alt="img" />
                  </Box>
                )}
            </Box>
            <Box component="div" className={classes.doughnutSection}>
              <Box component="div" className={classes.doughnutTitle}>
                <Box component="img" src={storageImage} alt="storage" />
                <Typography component="h5" variant="h5" className={classes.title1DoughnutSection}>
                  Almacenamiento
                </Typography>
              </Box>
              <Doughnut
                data={doughnutdata}
                options={{
                  circumference: 90 * Math.PI,
                  rotation: 70 * Math.PI,
                  cutout: '80%',
                }}
              />
              <Box component="div" className={classes.textMiddleDoughnutArea}>
                <Box
                  component="div"
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginTop: '10px',
                  }}
                >
                  <Typography component="h5" variant="h5" className={classes.doughnutInnerText}>
                    {`${usePercentage.toFixed(2)} %`}
                  </Typography>
                  <Typography component="h5" variant="h5" className={classes.doughnutInnerSectionText}>
                    {`${onUseStorage.toFixed(2)} GB de ${totalStorage} GB`}
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item md={8}>
            <Box component="div" className={classes.historyDivContainer}>
              <Box component="div" className={classes.historyTileSection}>
                <Typography component="h5" variant="h5" className={classes.title1HistorySection}>
                  Historial de Cambios
                </Typography>
                <Typography component="h5" variant="h5" className={classes.title2HistorySection}>
                  Actividad
                </Typography>
              </Box>
              {/* FOR FIRST TAB  */}
              {testerData1.length !== 0
                ? (
                  <Box>
                    <Box component="div" className={classes.descriptionHistorySection}>
                      <Box component="div" className={classes.descriptionSegment}>
                        <Typography component="h5" variant="h5" className={classes.descriptionText}>
                          Descripción
                        </Typography>
                      </Box>
                      <Box component="div" className={classes.userSegment}>
                        <Typography component="h5" variant="h5" className={classes.userText}>
                          Usuario
                        </Typography>
                      </Box>
                      <Box component="div" className={classes.timeDateSegment}>
                        <Typography component="h5" variant="h5" className={classes.dateText}>
                          Fecha
                        </Typography>
                        <Typography component="h5" variant="h5" className={classes.timeText}>
                          Hora
                        </Typography>
                      </Box>
                    </Box>

                    {testerData1.map((value) => (
                      <HistoryProjectDashboard
                        description={value.description}
                        user={value.user}
                        date={value.date}
                        time={value.time}
                      />
                    ))}
                  </Box>
                )
                : (
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignContent: 'center',
                      alignItems: 'center',
                      height: '90%',
                    }}
                  >
                    <Box
                      component="div"
                      style={{
                        display: 'flex',
                        height: '150px',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <NotInterestedIcon style={{fontSize: 100}} />
                    </Box>
                    <Typography component="h5" variant="h5">
                      No Hay Informacion
                    </Typography>
                  </Box>
                )}
            </Box>
            <Box component="div" className={classes.chartsSection}>
              <Box component="div" className={classes.chartPie}>
                <Typography component="h5" variant="h5" className={classes.title1ChartSection}>
                  Datos Prueba
                </Typography>
                <h2 className="text-center">
                  Muy Pronto
                </h2>
                {/* <Pie data={chartdata} /> */}
              </Box>
              <Box component="div" className={classes.optionsSection}>
                <Box component="div" className={classes.deployArea} style={{marginBottom: '10px', flexDirection: 'column'}}>
                  <Box component="div" className={classes.textDomainArea}>
                    Conecta tu Dominio
                  </Box>
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: 'auto',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      style={{
                        color: '#fff',
                        fontWeight: 'bold',
                        backgroundColor: domainSite ? 'rgba(41, 178, 239, 1)' : 'rgba(41, 33, 81, 1)',
                        fontFamily: [
                          'Nunito',
                          'Roboto',
                          'Helvetica Neue',
                          'Arial',
                          'sans-serif',
                        ].join(','),
                        textTransform: 'none',
                      }}
                      onClick={() => setOpenDomainAddDialog(true)}
                    >
                      {domainSite ? (
                        <>Editar o Actualizar Dominio</>
                      ) : (
                        <>Agregar Dominio</>
                      )}
                    </Button>
                  </Box>
                  {domainSite && (
                    <Box className={classes.domainTextStyle}>
                      {domainSite}
                    </Box>
                  )}
                </Box>
                <Box component="div" className={classes.deployArea}>
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: 'auto',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      style={{
                        color: 'black',
                        fontWeight: 'bold',
                        backgroundColor: 'rgba(178, 223, 138, 1)',
                        fontFamily: [
                          'Nunito',
                          'Roboto',
                          'Helvetica Neue',
                          'Arial',
                          'sans-serif',
                        ].join(','),
                        textTransform: 'none',
                      }}
                      onClick={async () => {
                        try {
                          const res = await updateDeploymentProject({
                            token, idProject,
                          });
                          if (res.data) {
                            toastr.success('Se genero exitosamente el proceso para actualizar el sitio');
                            history.push(`/dashboard/project/${idProject}/process/${res.data._id}`);
                          } else {
                            alert('Lo sentimos no se pudo generar el proceso de actualización, por favor contactar al correo de argesware@gmail.com para solucionar el problema.');
                          }
                        } catch (error) {
                          // console.log(error);
                          if (error?.info) {
                            toastr.error(error.info);
                          } else {
                            toastr.error('Ocurrio un error inesperado por favor intente más tarde');
                          }
                        }
                      }}
                    >
                      Actualizar
                    </Button>
                  </Box>
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: '50%',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Box component="img" src={updateImage} alt="update" />
                  </Box>
                </Box>
                {/* SECTION 2 */}
                <Box component="div" className={classes.deployArea2}>
                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: 'auto',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      style={{
                        margin: '20px',
                        color: '#fff',
                        fontWeight: 'bold',
                        backgroundColor: 'rgba(41, 33, 81, 1)',
                        fontFamily: [
                          'Nunito',
                          'Roboto',
                          'Helvetica Neue',
                          'Arial',
                          'sans-serif',
                        ].join(','),
                        textTransform: 'none',
                        width: '175px',
                      }}
                      onClick={() => {
                        alert('Muy Pronto');
                      }}
                    >
                      Cambiar Plan
                    </Button>
                  </Box>

                  <Box
                    component="div"
                    style={{
                      display: 'flex',
                      justifyContent: 'center',
                      height: 'auto',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      size="medium"
                      style={{
                        margin: '20px',
                        color: '#fff',
                        fontWeight: 'bold',
                        backgroundColor: 'rgba(41, 178, 239, 1)',
                        fontFamily: [
                          'Nunito',
                          'Roboto',
                          'Helvetica Neue',
                          'Arial',
                          'sans-serif',
                        ].join(','),
                        textTransform: 'none',
                        width: '175px',
                      }}
                      onClick={() => {
                        history.push(`/dashboard/project/${project._id}/backoffice`);
                      }}
                    >
                      Backoffice
                    </Button>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Layout>
  );
};

const mapStateToProps = (state) => state.auth;

export default connect(mapStateToProps)(DeployDashboard);
