import React from 'react';
import {Grid} from '@mui/material';
import PrincipalDashboard from '../../layouts/BackofficeDashboard';
import StorageChart from '../../components/StorageChart';
import WeeklyVisitsChart from '../../components/WeeklyVisitsChart';
import UserStatistics from '../../components/UserStatistics';

const DashboardPage = ({history, idProject, token}) => (
  <PrincipalDashboard history={history} idProject={idProject}>
    <Grid container spacing={1}>
      <Grid container item spacing={3} xs={12}>
        <Grid item xs={12} sm={6} md={6}>
          <StorageChart
            idProject={idProject}
            token={token}
            totalStorage={5}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6}>
          {token && <UserStatistics token={token} idProject={idProject} />}
          {token && <WeeklyVisitsChart projectId={idProject} />}
        </Grid>
      </Grid>

      {/* Fila para WeeklyVisitsChart */}
      {/* <Grid item xs={12} md={8}>
        </Grid> */}
    </Grid>
  </PrincipalDashboard>
);

export default DashboardPage;
