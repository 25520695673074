/* eslint-disable no-console */
const getInitialState = () => {
  try {
    const savedState = localStorage.getItem('iaProjectData');
    return savedState ? JSON.parse(savedState) : {};
  } catch (error) {
    console.error('Error loading state from localStorage:', error);
    return {};
  }
};

const initialState = getInitialState();

const saveState = (state) => {
  try {
    localStorage.setItem('iaProjectData', JSON.stringify(state));
  } catch (error) {
    console.error('Error saving state to localStorage:', error);
  }
};

export default function iaReducer(state = initialState, action) {
  let newState;

  switch (action.type) {
    case 'SET_PROJECT_DATA':
      newState = {...action.payload};
      break;
    case 'SET_SELECTED_PLAN':
      newState = {
        ...state,
        selectedPlan: action.payload,
      };
      break;
    case 'RESET_PROJECT_DATA':
      localStorage.removeItem('iaProjectData');
      newState = {};
      break;
    default:
      return state;
  }

  saveState(newState);
  return newState;
}
