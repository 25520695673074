import React, {useEffect, useState} from 'react';
import {Line} from 'react-chartjs-2';
import {Chart, registerables} from 'chart.js';
import {Divider} from '@mui/material';
import {filterVisitProjectsByDate} from 'api-lofty';

Chart.register(...registerables);

const WeeklyVisitsChart = ({projectId}) => {
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: 'Visitas Semanales',
        data: [],
        fill: false,
        borderColor: '#2163AE',
        backgroundColor: '#2163AE',
        tension: 0.1,
      },
    ],
  });

  useEffect(() => {
    const fetchVisitsData = async () => {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('Token de autenticación no encontrado');
        }

        const today = new Date();
        const startDate = new Date(today.setDate(today.getDate() - today.getDay()));
        const endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);

        const response = await filterVisitProjectsByDate(startDate, endDate, projectId, token);
        if (response.status === 'success' && response.data) {
          const visits = response.data;

          const visitsByDay = Array(7).fill(0);
          const labels = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];

          visits.forEach((visit) => {
            const visitDate = new Date(visit.date);
            const dayOfWeek = visitDate.getDay();
            visitsByDay[dayOfWeek] += parseInt(visit.count, 10);
          });

          setChartData({
            labels,
            datasets: [
              {
                label: 'Visitas Diarias',
                data: visitsByDay,
                fill: false,
                borderColor: '#2163AE',
                backgroundColor: '#2163AE',
                tension: 0.1,
              },
            ],
          });
        }
      } catch (error) {
        // Manejo de errores
      }
    };

    fetchVisitsData();
  }, [projectId]);

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Día de la Semana',
        },
        ticks: {
          callback: (value, index) => chartData.labels[index],
        },
      },
      y: {
        beginAtZero: true,
        title: {
          display: true,
          text: 'Cantidad de Visitas',
        },
        ticks: {
          stepSize: 1,
          precision: 0,
        },
      },
    },
  };

  return (
    <div style={{
      backgroundColor: 'white',
      width: '100%',
      minHeight: '400px',
      borderRadius: '12px',
      boxShadow: '0 0 15px rgba(0, 0, 0, 0.1)',
      position: 'relative',
      padding: '30px',
      boxSizing: 'border-box',
    }}
    >
      <div style={{
        position: 'absolute',
        top: '20px',
        right: '30px',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        color: '#433C66',
        fontWeight: 'bold',
        fontSize: '1.5rem',
      }}
      >
        Visitas Semanales
      </div>
      <Divider sx={{my: 4}} />
      <div style={{
        width: '100%', height: '80%', position: 'relative', marginTop: '30px',
      }}
      >
        <Line data={chartData} options={options} />
      </div>
    </div>
  );
};

export default WeeklyVisitsChart;
