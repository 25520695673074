/* eslint-disable max-len */
import React, {useEffect, useRef, useState} from 'react';
import {Box, IconButton} from '@mui/material';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import PauseIcon from '@mui/icons-material/Pause';

const PongGame = () => {
  const canvasRef = useRef(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const gameStateRef = useRef({
    player: null,
    ai: null,
    ball: null,
    animationFrameId: null,
  });

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext('2d');

    // Configuración inicial
    const paddleWidth = 10;
    const paddleHeight = 80;
    const ballSize = 15;
    const paddleOffset = 30;

    const player = {
      x: paddleOffset,
      y: (canvas.height - paddleHeight) / 2,
      width: paddleWidth,
      height: paddleHeight,
      dy: 0,
      score: 0,
    };

    const ai = {
      x: canvas.width - paddleWidth - paddleOffset,
      y: (canvas.height - paddleHeight) / 2,
      width: paddleWidth,
      height: paddleHeight,
      dy: 0,
      score: 0,
    };

    const ball = {
      x: canvas.width / 2,
      y: canvas.height / 2,
      width: ballSize,
      height: ballSize,
      dx: 5 * (Math.random() > 0.5 ? 1 : -1),
      dy: 5 * (Math.random() > 0.5 ? 1 : -1),
    };

    // Dibujar línea punteada central
    const drawDashedLine = () => {
      const dashLength = 8;
      const gapLength = 12;
      const startY = 0;
      const endY = canvas.height;
      const x = canvas.width / 2;

      context.beginPath();
      context.strokeStyle = 'white';
      context.lineWidth = 1;
      context.setLineDash([dashLength, gapLength]);
      context.moveTo(x, startY);
      context.lineTo(x, endY);
      context.stroke();
      context.setLineDash([]);
    };

    // Funciones auxiliares
    const drawRect = (x, y, width, height, color = 'white') => {
      context.fillStyle = color;
      if (width === paddleWidth) {
        context.shadowColor = 'rgba(255, 255, 255, 0.5)';
        context.shadowBlur = 10;
      }
      context.fillRect(x, y, width, height);
      context.shadowBlur = 0;
    };

    const drawText = (text, x, y, size = 20) => {
      context.fillStyle = 'white';
      context.font = `${size === 20 ? 32 : size}px "SF Mono", monospace`;
      context.textAlign = 'center';
      context.fillText(text, x, y);
    };

    const resetBall = () => {
      ball.x = canvas.width / 2;
      ball.y = canvas.height / 2;

      // Velocidad base
      const baseSpeed = 5;

      // Ángulo aleatorio entre -45 y 45 grados
      const angle = (Math.random() * 90 - 45) * (Math.PI / 180);

      // Dirección aleatoria (izquierda o derecha)
      const direction = Math.random() > 0.5 ? 1 : -1;

      // Asignar directamente las velocidades
      ball.dx = baseSpeed * direction;
      ball.dy = baseSpeed * Math.sin(angle);
    };

    // Agregar delay al resetear la pelota
    const resetBallWithDelay = () => {
      const currentScore = {player: player.score, ai: ai.score};
      ball.dx = 0;
      ball.dy = 0;
      ball.x = canvas.width / 2;
      ball.y = canvas.height / 2;
      setTimeout(() => {
        if (currentScore.player === player.score && currentScore.ai === ai.score) {
          gameStateRef.current.resetBall();
        }
      }, 1000);
    };

    // Dibujar estado inicial
    const drawInitialState = () => {
      drawRect(0, 0, canvas.width, canvas.height, 'black');
      drawDashedLine();
      drawRect(player.x, player.y, player.width, player.height);
      drawRect(ai.x, ai.y, ai.width, ai.height);
      drawRect(
        ball.x - (ball.width / 2),
        ball.y - (ball.height / 2),
        ball.width,
        ball.height,
      );
      drawText('0', canvas.width / 4, 80);
      drawText('0', (3 * canvas.width) / 4, 80);
    };

    // Actualizar posiciones
    const update = () => {
      player.y += player.dy;
      ai.y += (ball.y - (ai.y + ai.height / 2)) * 0.08;
      if (Math.random() < 0.1) {
        ai.y += (Math.random() - 0.5) * 10;
      }

      player.y = Math.max(Math.min(player.y, canvas.height - player.height), 0);
      ai.y = Math.max(Math.min(ai.y, canvas.height - ai.height), 0);

      ball.x += ball.dx;
      ball.y += ball.dy;

      if (ball.y <= 0 || ball.y + ball.height >= canvas.height) {
        ball.dy *= -1;
      }

      const collideWithPaddle = (paddle) => ball.x < paddle.x + paddle.width
        && ball.x + ball.width > paddle.x
        && ball.y < paddle.y + paddle.height
        && ball.y + ball.height > paddle.y;

      if (collideWithPaddle(player) || collideWithPaddle(ai)) {
        ball.dx *= -1.05;
        ball.dx = Math.min(Math.abs(ball.dx), 10) * Math.sign(ball.dx);
        const hitPos = (ball.y - (collideWithPaddle(player) ? player.y : ai.y)) / paddleHeight;
        ball.dy = (hitPos - 0.5) * 8;
      }
      if (ball.x < 0) {
        ai.score += 1;
        resetBallWithDelay();
      } else if (ball.x > canvas.width) {
        player.score += 1;
        resetBallWithDelay();
      }
    };

    // Renderizar
    const render = () => {
      drawRect(0, 0, canvas.width, canvas.height, 'black');
      drawDashedLine();
      drawRect(player.x, player.y, player.width, player.height);
      drawRect(ai.x, ai.y, ai.width, ai.height);
      drawRect(
        ball.x - (ball.width / 2),
        ball.y - (ball.height / 2),
        ball.width,
        ball.height,
      );
      drawText(player.score, canvas.width / 4, 80);
      drawText(ai.score, (3 * canvas.width) / 4, 80);
      gameStateRef.current.lastRender = {
        ball: {...ball},
        player: {...player},
        ai: {...ai},
      };
      if (!gameStarted) {
        context.fillStyle = 'rgba(255, 255, 255, 0.7)';
        context.font = '14px "SF Mono", monospace';
        context.textAlign = 'center';
        context.fillText('Presiona ESPACIO para jugar', canvas.width / 2, 40);
      }
    };

    // Game loop
    const gameLoop = () => {
      if (gameStarted && !isPaused) {
        update();
      }
      render();
      gameStateRef.current.animationFrameId = requestAnimationFrame(gameLoop);
    };

    // Guardar referencias para usar fuera del useEffect
    gameStateRef.current = {
      player,
      ai,
      ball,
      drawInitialState,
      resetBall,
      gameLoop,
    };

    // Llamar a resetBall al inicio para asegurar una dirección inicial
    resetBall();

    // Controles
    const handleKeyDown = (e) => {
      if (e.code === 'Space') {
        if (!gameStarted) {
          setGameStarted(true);
          gameStateRef.current.resetBall();
        }
      }
      if (e.key === 'ArrowUp') player.dy = -6;
      if (e.key === 'ArrowDown') player.dy = 6;
    };

    const handleKeyUp = (e) => {
      if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
        player.dy = 0;
      }
    };

    // Iniciar el juego
    gameLoop();

    // Mostrar estado inicial
    drawInitialState();

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('keyup', handleKeyUp);
      if (gameStateRef.current.animationFrameId) {
        cancelAnimationFrame(gameStateRef.current.animationFrameId);
      }
    };
  }, [gameStarted, isPaused]);

  const handleReset = () => {
    const {
      player, ai, ball, drawInitialState,
    } = gameStateRef.current;
    if (player && ai && ball) {
      player.score = 0;
      ai.score = 0;
      gameStateRef.current.resetBall();
      setGameStarted(false);
      setIsPaused(false);
      drawInitialState();
    }
  };

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      height: '100%',
    }}
    >
      <canvas
        ref={canvasRef}
        width={1000}
        height={600}
        style={{
          background: 'black',
          width: '100%',
          height: '100%',
          objectFit: 'fill',
          borderRadius: '12px',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          bottom: '5%',
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          gap: 2,
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          padding: '8px',
          borderRadius: '20px',
          backdropFilter: 'blur(5px)',
        }}
      >
        <IconButton
          onClick={() => {
            if (!gameStarted) {
              setGameStarted(true);
              setIsPaused(false);
              gameStateRef.current.resetBall();
            } else {
              setIsPaused(!isPaused);
            }
          }}
          sx={{
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          {!gameStarted || isPaused ? <PlayArrowIcon /> : <PauseIcon />}
        </IconButton>
        <IconButton
          onClick={handleReset}
          sx={{
            color: 'white',
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.1)',
            },
          }}
        >
          <RestartAltIcon />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PongGame;
