import React from 'react';
import {Box} from '@mui/material';
import {withRouter} from 'react-router-dom';

const IAGenerationLayout = ({children}) => (
  <Box
    sx={{
      minHeight: '100vh',
      background: 'linear-gradient(135deg, #0046B5 0%, #00A3FF 100%)',
      display: 'flex',
      flexDirection: 'column',
      p: {xs: '0.5rem', md: '1rem'},
    }}
  >
    <Box
      sx={{
        background: '#FFFFFF',
        flex: 1,
        mx: 'auto',
        width: '100%',
        maxWidth: '1800px',
        height: {xs: '100vh', md: 'calc(100vh - 2rem)'},
        backgroundColor: '#FFFFFF',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        overflow: 'hidden',
        display: 'flex',
      }}
    >
      <Box
        sx={{
          width: '100%',
          height: '100%',
          maxWidth: '1600px',
          display: 'flex',
          overflow: {xs: 'auto', md: 'hidden'},
          flexDirection: {xs: 'column', md: 'row'},
        }}
      >
        {children}
      </Box>
    </Box>
  </Box>
);

export default withRouter(IAGenerationLayout);
