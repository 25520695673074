import {createStore, combineReducers, applyMiddleware} from 'redux';
import authReducer from './auth.reducer';
import iaReducer from './ia.reducer';
import {localStorageMiddleware} from '../middleware/localStorageMiddleware';
import reducerBackoffice from './backoffice.reducer';

const rootReducer = combineReducers({
  auth: authReducer,
  ia: iaReducer,
  backoffice: reducerBackoffice,
});

export const store = createStore(
  rootReducer,
  applyMiddleware(localStorageMiddleware),
);

export default rootReducer;
