/* eslint-disable max-len */
import React, {useRef, useEffect, useState} from 'react';
import {Box, IconButton} from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

const PongGameMobile = () => {
  const canvasRef = useRef(null);
  const paddleRef = useRef(150);
  const [dimensions, setDimensions] = useState({width: 400, height: 300});
  const paddleSpeed = 15;
  const [playerScore, setPlayerScore] = useState(0);
  const [aiScore, setAiScore] = useState(0);
  const moveIntervalRef = useRef(null);
  const [gameStarted, setGameStarted] = useState(false);
  const [isPaused, setIsPaused] = useState(false);
  const animationFrameIdRef = useRef(null);

  // Efecto para manejar el resize y dimensiones del canvas
  useEffect(() => {
    const updateDimensions = () => {
      const container = canvasRef.current?.parentElement;
      if (container) {
        const containerWidth = container.clientWidth;
        const containerHeight = container.clientHeight;
        const aspectRatio = 4 / 3;

        let newWidth = containerWidth * 0.9;
        let newHeight = newWidth / aspectRatio;

        if (newHeight > containerHeight * 0.9) {
          newHeight = containerHeight * 0.9;
          newWidth = newHeight * aspectRatio;
        }

        setDimensions({
          width: Math.floor(newWidth),
          height: Math.floor(newHeight),
        });
      }
    };

    updateDimensions();
    window.addEventListener('resize', updateDimensions);
    return () => window.removeEventListener('resize', updateDimensions);
  }, []);

  const resetBall = (ball, canvas, isAiScore = false) => {
    setIsPaused(true);
    ball.x = canvas.width / 2;
    ball.y = canvas.height / 2;
    if (isAiScore) {
      setAiScore((prev) => prev + 1);
    } else {
      setPlayerScore((prev) => prev + 1);
    }
    setTimeout(() => {
      setIsPaused(false);
    }, 1000);
  };

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const context = canvas.getContext('2d');

    canvas.width = dimensions.width;
    canvas.height = dimensions.height;

    const ball = {
      x: canvas.width / 2,
      y: canvas.height / 2,
      dx: canvas.width * 0.006,
      dy: canvas.width * 0.006,
      radius: Math.max(canvas.width * 0.01, 5),
    };

    const paddle = {
      width: Math.max(canvas.width * 0.02, 10),
      height: canvas.height * 0.2,
      y: canvas.height / 2 - (canvas.height * 0.2) / 2,
    };

    const aiPaddle = {
      width: paddle.width,
      height: paddle.height,
      y: canvas.height / 2 - (canvas.height * 0.2) / 2,
    };

    // Inicializar la posición del paddle del jugador
    paddleRef.current = paddle.y;

    const drawInitialScreen = () => {
      context.fillStyle = '#000000';
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Dibujar paddles iniciales centrados
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, paddleRef.current, paddle.width, paddle.height);
      context.fillRect(canvas.width - paddle.width, aiPaddle.y, paddle.width, paddle.height);

      // Dibujar mensaje de inicio
      context.font = `${Math.max(canvas.width * 0.04, 16)}px Arial`;
      context.fillStyle = '#FFFFFF';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText('Toca para jugar', canvas.width / 2, canvas.height / 2);
    };

    const draw = () => {
      if (!gameStarted) {
        drawInitialScreen();
        return;
      }

      context.fillStyle = '#000000';
      context.fillRect(0, 0, canvas.width, canvas.height);

      // Dibujar score
      context.font = `${Math.max(canvas.width * 0.05, 20)}px Arial`;
      context.fillStyle = '#FFFFFF';
      context.textAlign = 'center';
      context.textBaseline = 'middle';
      context.fillText(playerScore.toString(), canvas.width * 0.25, canvas.height * 0.2);
      context.fillText(aiScore.toString(), canvas.width * 0.75, canvas.height * 0.2);

      // Dibujar líneas del medio
      const lineWidth = Math.max(canvas.width * 0.005, 2);
      const lineHeight = canvas.height * 0.03;
      const gap = lineHeight * 1.5;
      const startY = (canvas.height - (Math.floor(canvas.height / (lineHeight + gap)) * (lineHeight + gap))) / 2;

      for (let y = startY; y < canvas.height - lineHeight; y += lineHeight + gap) {
        context.fillRect(
          canvas.width / 2 - lineWidth / 2,
          y,
          lineWidth,
          lineHeight,
        );
      }

      // Dibujar pelota
      context.beginPath();
      context.arc(ball.x, ball.y, ball.radius, 0, Math.PI * 2);
      context.fillStyle = '#FFFFFF';
      context.fill();
      context.closePath();

      // Dibujar paddles
      context.fillStyle = '#FFFFFF';
      context.fillRect(0, paddleRef.current, paddle.width, paddle.height);
      context.fillRect(canvas.width - paddle.width, aiPaddle.y, paddle.width, paddle.height);

      if (gameStarted && !isPaused) {
        ball.x += ball.dx;
        ball.y += ball.dy;

        if (ball.y + ball.dy > canvas.height - ball.radius || ball.y + ball.dy < ball.radius) {
          ball.dy = -ball.dy;
        }

        const playerPaddleCollision = (
          ball.x - ball.radius < paddle.width
          && ball.y > paddleRef.current
          && ball.y < paddleRef.current + paddle.height
        );

        const aiPaddleCollision = (
          ball.x + ball.radius > canvas.width - paddle.width
          && ball.y > aiPaddle.y
          && ball.y < aiPaddle.y + paddle.height
        );

        if (playerPaddleCollision || aiPaddleCollision) {
          ball.dx = -ball.dx;
        }

        // Reset si la pelota sale y actualizar score
        if (ball.x < 0) {
          resetBall(ball, canvas, true);
        } else if (ball.x > canvas.width) {
          resetBall(ball, canvas, false);
        }

        const aiSpeed = 4;
        const paddleCenter = aiPaddle.y + aiPaddle.height / 2;
        if (paddleCenter < ball.y - 35) {
          aiPaddle.y += aiSpeed;
        } else if (paddleCenter > ball.y + 35) {
          aiPaddle.y -= aiSpeed;
        }
      }

      animationFrameIdRef.current = window.requestAnimationFrame(draw);
    };

    draw();

    // eslint-disable-next-line consistent-return
    return () => {
      window.cancelAnimationFrame(animationFrameIdRef.current);
    };
  }, [dimensions, playerScore, aiScore, gameStarted, isPaused]);

  const handleStartGame = () => {
    if (!gameStarted) {
      setGameStarted(true);
    }
  };

  const startMoving = (direction) => {
    if (moveIntervalRef.current) {
      clearInterval(moveIntervalRef.current);
    }

    moveIntervalRef.current = setInterval(() => {
      if (direction === 'up') {
        paddleRef.current = Math.max(0, paddleRef.current - paddleSpeed);
      } else {
        const maxY = canvasRef.current.height - 60;
        paddleRef.current = Math.min(maxY, paddleRef.current + paddleSpeed);
      }
    }, 16);
  };

  const stopMoving = () => {
    if (moveIntervalRef.current) {
      clearInterval(moveIntervalRef.current);
      moveIntervalRef.current = null;
    }
  };

  return (
    <Box sx={{
      position: 'relative',
      width: '100%',
      height: '100%',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: '#000000',
    }}
    >
      <Box
        onClick={handleStartGame}
        sx={{
          width: '100%',
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          position: 'relative',
          cursor: !gameStarted ? 'pointer' : 'default',
        }}
      >
        <canvas
          ref={canvasRef}
          style={{
            display: 'block',
            width: '100%',
            height: '100%',
            objectFit: 'contain',
          }}
        />
      </Box>
      <Box sx={{
        position: 'absolute',
        bottom: {xs: '10%', sm: '15%'},
        left: '50%',
        transform: 'translateX(-50%)',
        display: 'flex',
        flexDirection: 'row',
        gap: 4,
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        padding: 2,
        borderRadius: 2,
        zIndex: 1,
      }}
      >
        <IconButton
          onTouchStart={() => startMoving('up')}
          onMouseDown={() => startMoving('up')}
          onTouchEnd={stopMoving}
          onMouseUp={stopMoving}
          onMouseLeave={stopMoving}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: 'white',
            width: {xs: 48, sm: 56},
            height: {xs: 48, sm: 56},
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
            },
          }}
        >
          <KeyboardArrowUpIcon sx={{fontSize: {xs: 24, sm: 32}}} />
        </IconButton>
        <IconButton
          onTouchStart={() => startMoving('down')}
          onMouseDown={() => startMoving('down')}
          onTouchEnd={stopMoving}
          onMouseUp={stopMoving}
          onMouseLeave={stopMoving}
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.2)',
            color: 'white',
            width: {xs: 48, sm: 56},
            height: {xs: 48, sm: 56},
            '&:hover': {
              backgroundColor: 'rgba(255, 255, 255, 0.3)',
            },
          }}
        >
          <KeyboardArrowDownIcon sx={{fontSize: {xs: 24, sm: 32}}} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default PongGameMobile;
