import React from 'react';
import {Box} from '@mui/material';

const IACompleteLayout = ({children}) => (
  <Box
    sx={{
      width: '100vw',
      height: '100vh',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      background: 'linear-gradient(135deg, #0046B5 0%, #00A3FF 100%)',
      p: {
        xs: 1,
        sm: 2,
        md: 2,
        xl: 3,
      },
    }}
  >
    <Box
      sx={{
        width: '100%',
        height: '100%',
        maxWidth: '1800px',
        maxHeight: {
          xs: '100%',
          sm: '100%',
          md: '100%',
          xl: '900px',
        },
        backgroundColor: '#0A0B14',
        overflow: {
          xs: 'auto',
          sm: 'auto',
          md: 'auto',
          xl: 'hidden',
        },
        display: 'flex',
        flexDirection: {
          xs: 'column',
          sm: 'column',
          md: 'column',
          xl: 'row',
        },
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.15)',
      }}
    >
      {children}
    </Box>
  </Box>
);

export default IACompleteLayout;
