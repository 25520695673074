/* eslint-disable no-console */
import React from 'react';
import useParticles from '../hooks/useParticles';

const ParticlesBackground = () => {
  useParticles('particles-js');

  return (
    <div
      id="particles-js"
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1,
      }}
    />
  );
};

export default ParticlesBackground;
