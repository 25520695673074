import React, {useState, useEffect} from 'react';
import {
  Box,
  Typography,
  TextField,
  Paper,
} from '@mui/material';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import IALayout from '../layouts/IALayout';
import pagesPlaceholder from '../assets/ia/pages_placeholder.png';
import editIcon from '../assets/ia/Edit Property.png';
import checkmarkIcon from '../assets/ia/Checkmark.png';

const PageItem = ({
  page,
  isSelected,
  onSelect,
  index,
  isComplete,
}) => (
  <Paper
    elevation={isSelected ? 3 : 1}
    sx={{
      cursor: 'pointer',
      backgroundColor: '#FFFFFF',
      display: 'flex',
      flexDirection: 'column',
      border: isSelected ? '2px solid #29B2EF' : '1px solid #E0E0E0',
      borderRadius: '4px',
      overflow: 'hidden',
      aspectRatio: '1',
      width: '100%',
      '@media (max-width: 899px)': {
        minWidth: 0,
      },
    }}
    onClick={onSelect}
  >
    <Box sx={{
      width: '100%',
      backgroundColor: '#29B2EF',
      color: 'white',
      p: 1.5,
      display: 'flex',
      alignItems: 'center',
      gap: 1,
      height: '48px',
      '@media (max-width: 899px)': {
        height: '40px',
        p: 1,
      },
      '@media (max-width: 599px)': {
        height: '32px',
        p: 0.5,
      },
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
        gap: 1,
      }}
      >
        <Typography sx={{
          fontSize: '14px',
          '@media (max-width: 899px)': {
            fontSize: '12px',
          },
          '@media (max-width: 599px)': {
            fontSize: '11px',
          },
        }}
        >
          {`${index + 1}. ${page.name || 'Sin nombre'}`}
        </Typography>
        {isSelected ? (
          <Box
            component="img"
            src={editIcon}
            alt="Editing"
            sx={{
              width: '16px',
              height: '16px',
              '@media (max-width: 899px)': {
                width: '14px',
                height: '14px',
              },
              '@media (max-width: 599px)': {
                width: '12px',
                height: '12px',
              },
            }}
          />
        ) : isComplete && (
          <Box
            component="img"
            src={checkmarkIcon}
            alt="Completed"
            sx={{
              width: '16px',
              height: '16px',
              '@media (max-width: 899px)': {
                width: '14px',
                height: '14px',
              },
              '@media (max-width: 599px)': {
                width: '12px',
                height: '12px',
              },
            }}
          />
        )}
      </Box>
    </Box>

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: 1,
      height: 'calc(100% - 48px)',
      '@media (max-width: 899px)': {
        height: 'calc(100% - 40px)',
      },
      '@media (max-width: 599px)': {
        height: 'calc(100% - 32px)',
      },
    }}
    >
      <Box
        component="img"
        src={pagesPlaceholder}
        alt="Page preview"
        sx={{
          width: '100%',
          height: '100%',
          objectFit: 'contain',
          backgroundColor: '#F8F9FA',
        }}
      />
    </Box>
  </Paper>
);

const IAProjectPagesInfo = ({
  projectData, setProjectData, history,
}) => {
  const MIN_DESCRIPTION_LENGTH = 50; // Mínimo de caracteres para la descripción
  const [selectedPageIndex, setSelectedPageIndex] = useState(0);
  const [pages, setPages] = useState(() => {
    if (projectData?.pages?.length) {
      return projectData.pages;
    }
    const pageCount = projectData?.pageCount || 3;
    return Array.from({length: pageCount}, (_, index) => ({
      id: index + 1,
      name: index === 0 ? 'Inicio' : '',
      description: '',
      isDefault: index === 0,
    }));
  });

  const handlePageSelect = (index) => {
    // Verificar si la página actual está completa antes de permitir cambiar
    const currentPage = pages[selectedPageIndex];
    const isCurrentPageComplete = currentPage.name.trim() !== ''
      && currentPage.description.trim().length >= MIN_DESCRIPTION_LENGTH;

    if (isCurrentPageComplete || selectedPageIndex === index) {
      setSelectedPageIndex(index);
    }
  };

  const handlePageNameChange = (newName) => {
    const newPages = [...pages];
    newPages[selectedPageIndex].name = newName;
    setPages(newPages);
  };

  const handleDescriptionChange = (description) => {
    const newPages = [...pages];
    newPages[selectedPageIndex].description = description;
    setPages(newPages);
  };

  const isValid = pages.every((page) => page.name.trim() !== ''
    && page.description.trim().length >= MIN_DESCRIPTION_LENGTH);

  const currentPageIsValid = pages[selectedPageIndex]?.name.trim() !== ''
    && pages[selectedPageIndex]?.description.trim().length >= MIN_DESCRIPTION_LENGTH;

  const totalPages = projectData?.pageCount || 3;
  const isLastPage = selectedPageIndex === pages.length - 1;

  const handleBack = () => {
    if (selectedPageIndex === 0) {
      setProjectData({
        ...projectData,
        pages,
      });
      history.push('/ia/step2');
    } else {
      setSelectedPageIndex(selectedPageIndex - 1);
    }
  };

  const handleNext = () => {
    if (isLastPage) {
      if (isValid) {
        setProjectData({
          ...projectData,
          pages,
        });
        history.push('/ia/generation');
      }
    } else {
      setSelectedPageIndex(selectedPageIndex + 1);
    }
  };

  useEffect(() => {
    if (projectData?.pages?.length) {
      setPages(projectData.pages);
    }
  }, [projectData]);

  useEffect(() => {
    if (JSON.stringify(pages) !== JSON.stringify(projectData?.pages)) {
      setProjectData({
        ...projectData,
        pages,
      });
    }
  }, [pages]);

  return (
    <IALayout
      canContinue={isLastPage ? isValid : currentPageIsValid}
      onNext={handleNext}
      onBack={handleBack}
      customBackText={selectedPageIndex === 0 ? 'Regresar' : 'Editar anterior'}
      customNextText={isLastPage ? 'Finalizar' : 'Editar siguiente'}
      pageCounter={`${selectedPageIndex + 1}/${totalPages}`}
      customNextButtonStyle={!isLastPage ? {
        background: '#29B2EF',
        color: '#FFFFFF',
        '&:hover': {
          background: '#1A9CD7',
        },
      } : undefined}
    >
      <Box sx={{
        height: '100%',
        display: 'flex',
        flex: 1,
        overflow: 'hidden',
        position: 'relative',
        flexDirection: {xs: 'column', sm: 'column', md: 'row'},
      }}
      >
        <Box sx={{
          flex: '1 1 65%',
          background: '#C2D1D9',
          borderRight: '1px solid #E0E0E0',
          position: 'relative',
          overflow: 'hidden',
          padding: '2rem 0',
          '@media (max-width: 899px)': {
            flex: '1 1 auto',
            order: 2,
            minHeight: '500px',
            borderRight: 'none',
            padding: '1rem 0',
          },
          '@media (max-width: 599px)': {
            minHeight: '400px',
          },
        }}
        >
          <Box sx={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, minmax(200px, 1fr))',
            width: '100%',
            maxWidth: '80%',
            gap: '24px',
            justifyContent: 'center',
            alignContent: 'center',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            margin: '0 auto',
            '@media (max-width: 899px)': {
              gridTemplateColumns: 'repeat(3, 1fr)',
              maxWidth: '95%',
              gap: '12px',
              gridAutoRows: '1fr',
            },
            '@media (max-width: 599px)': {
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '8px',
              maxWidth: '98%',
            },
          }}
          >
            {pages.map((page, index) => (
              <PageItem
                key={page.id}
                page={page}
                index={index}
                isSelected={index === selectedPageIndex}
                onSelect={() => handlePageSelect(index)}
                isComplete={page.name.trim() !== '' && page.description.trim().length >= MIN_DESCRIPTION_LENGTH}
              />
            ))}
          </Box>
        </Box>

        <Box sx={{
          flex: '1 1 35%',
          background: '#FFFFFF',
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%',
          '@media (max-width: 899px)': {
            flex: 'none',
            order: 1,
            width: '100%',
            p: 3,
            height: 'auto',
          },
          '@media (max-width: 599px)': {
            p: 2,
          },
        }}
        >
          <Box sx={{
            width: '90%',
            maxWidth: '500px',
            pt: 8,
            '@media (max-width: 899px)': {
              width: '100%',
              maxWidth: '600px',
              pt: 0,
            },
          }}
          >
            <Typography
              variant="h4"
              sx={{
                mb: 2,
                color: '#333333',
                fontWeight: 500,
                fontSize: '24px',
                lineHeight: 1.2,
              }}
            >
              EDITAR INFORMACIÓN DE LA PÁGINA
            </Typography>

            <Typography
              variant="subtitle1"
              sx={{
                mb: 3,
                color: '#666666',
                fontSize: '14px',
              }}
            >
              Agrega detalles acerca de esta sección del sitio
            </Typography>

            <TextField
              label="Nombre de la página"
              value={pages[selectedPageIndex]?.name || ''}
              onChange={(e) => handlePageNameChange(e.target.value)}
              fullWidth
              variant="outlined"
              placeholder="Ej: Inicio, Acerca de, Contacto"
              sx={{
                mb: 3,
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  backgroundColor: '#FFFFFF',
                  height: '45px',
                  '& fieldset': {
                    borderColor: '#E0E0E0',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#666666',
                  fontSize: '14px',
                },
              }}
            />

            <TextField
              label="Descripción de la sección"
              value={pages[selectedPageIndex]?.description || ''}
              onChange={(e) => handleDescriptionChange(e.target.value)}
              multiline
              rows={4}
              fullWidth
              variant="outlined"
              helperText={`Mínimo ${MIN_DESCRIPTION_LENGTH} caracteres. ${
                pages[selectedPageIndex]?.description.length || 0
              }/${MIN_DESCRIPTION_LENGTH}`}
              error={pages[selectedPageIndex]?.description.length > 0
                && pages[selectedPageIndex]?.description.length < MIN_DESCRIPTION_LENGTH}
              placeholder={`Describe el contenido que irá en la página "${pages[selectedPageIndex]?.name || 'sin nombre'}"`}
              sx={{
                '& .MuiOutlinedInput-root': {
                  borderRadius: 1,
                  backgroundColor: '#FFFFFF',
                  '& fieldset': {
                    borderColor: '#E0E0E0',
                  },
                },
                '& .MuiInputLabel-root': {
                  color: '#666666',
                  fontSize: '14px',
                },
              }}
            />
          </Box>
        </Box>
      </Box>
    </IALayout>
  );
};

const mapStateToProps = (state) => ({
  projectData: state.ia,
});

const mapDispatchToProps = (dispatch) => ({
  setProjectData: (data) => dispatch({type: 'SET_PROJECT_DATA', payload: data}),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IAProjectPagesInfo));
