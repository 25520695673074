import React, {useState, useEffect} from 'react';
import {
  Box,
  Button,
  Stack,
  Typography,
  LinearProgress,
} from '@mui/material';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import homeBackgroundMobile from '../assets/ia/home_background.png';
import homeBackgroundDesktop from '../assets/ia/home_background_desktop.png';

const steps = [
  {path: '/ia/step1', label: 'Tipo y Estilo'},
  {path: '/ia/step2', label: 'Información del sitio'},
  {path: '/ia/step3', label: 'Detalles del sitio web y páginas'},
];

const IALayout = ({
  children,
  canContinue = true,
  history,
  location,
  onNext,
  onBack,
  customBackText,
  customNextText,
  pageCounter,
  customNextButtonStyle,
  projectData,
  resetProjectData,
}) => {
  const [loading, setLoading] = useState(false);
  const currentStepIndex = steps.findIndex((step) => step.path === location.pathname);

  useEffect(() => {
    if (currentStepIndex > 0) {
      if (currentStepIndex === 1 && !projectData?.projectType) {
        history.push('/ia/step1');
        return;
      }

      if (currentStepIndex === 2 && !projectData?.companyName) {
        history.push('/ia/step2');
      }
    }
  }, [location.pathname, projectData, history]);

  const handleBack = () => {
    if (currentStepIndex === 0) {
      resetProjectData();
      history.push('/ia');
    } else if (onBack) {
      onBack();
    } else {
      history.push(steps[currentStepIndex - 1].path);
    }
  };

  const handleNext = async () => {
    if (onNext) {
      setLoading(true);
      try {
        await onNext();
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error:', error);
      }
      setLoading(false);
    } else if (currentStepIndex < steps.length - 1) {
      history.push(steps[currentStepIndex + 1].path);
    }
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        background: 'linear-gradient(135deg, #0046B5 0%, #00A3FF 100%)',
        display: 'flex',
        flexDirection: 'column',
        padding: '1rem',
      }}
    >
      {loading && (
        <LinearProgress
          sx={{
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 1100,
          }}
        />
      )}

      <Box
        sx={{
          background: '#FFFFFF',
          flex: 1,
          padding: {xs: '1rem', sm: '1rem', md: 0},
          margin: '0 auto',
          width: '100%',
          maxWidth: '1800px',
          minHeight: 'calc(100vh - 2rem)',
          position: 'relative',
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto',
          backgroundImage: location.pathname === '/ia'
            ? {
              xs: `url(${homeBackgroundMobile})`,
              md: `url(${homeBackgroundDesktop})`,
            }
            : 'none',
          backgroundSize: '100% 100%',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
        }}
      >
        {children}
        {location.pathname !== '/ia' && (
          <Box
            sx={{
              mt: 'auto',
              pt: 3,
              borderTop: '1px solid #E0E0E0',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              position: 'relative',
              bottom: 0,
              background: '#FFFFFF',
              zIndex: 10,
              flexDirection: {xs: 'column', sm: 'column', md: 'row'},
              gap: {xs: 2, sm: 2, md: 0},
              padding: {xs: '1rem', sm: '1rem', md: '1rem'},
            }}
          >
            <Button
              variant="outlined"
              onClick={handleBack}
              sx={{
                minWidth: '120px',
                borderColor: '#1A1147',
                color: '#1A1147',
                order: {xs: 1, sm: 1, md: 0},
                width: {xs: '80%', sm: '60%', md: 'auto'},
                maxWidth: {xs: '300px', sm: '250px', md: 'none'},
              }}
            >
              {customBackText || 'Regresar'}
            </Button>

            <Stack
              direction="row"
              spacing={4}
              sx={{
                order: {xs: 0, sm: 0, md: 1},
                width: {xs: '100%', sm: '100%', md: 'auto'},
                justifyContent: {xs: 'center', sm: 'center', md: 'flex-start'},
              }}
            >
              {steps.map((step, index) => (
                <Typography
                  key={step.path}
                  sx={{
                    color: index === currentStepIndex ? '#1A1147' : '#757575',
                    borderBottom: index === currentStepIndex ? '2px solid #1A1147' : 'none',
                    pb: 1,
                    fontSize: {xs: '0.875rem', sm: '0.875rem', md: '1rem'},
                  }}
                >
                  {step.label}
                </Typography>
              ))}
            </Stack>

            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                gap: 1,
                order: {xs: 2, sm: 2, md: 2},
                width: {xs: '100%', sm: '100%', md: 'auto'},
                justifyContent: {xs: 'center', sm: 'center', md: 'flex-start'},
              }}
            >
              {pageCounter && (
                <Typography
                  sx={{
                    color: '#666666',
                    fontSize: '14px',
                    mr: 2,
                  }}
                >
                  {pageCounter}
                </Typography>
              )}
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!canContinue}
                sx={{
                  minWidth: '120px',
                  background: '#1A1147',
                  color: '#FFFFFF',
                  width: {xs: '80%', sm: '60%', md: 'auto'},
                  maxWidth: {xs: '300px', sm: '250px', md: 'none'},
                  '&:hover': {
                    background: '#2A1F5F',
                  },
                  '&.Mui-disabled': {
                    color: 'rgba(255, 255, 255, 0.7)',
                  },
                  ...customNextButtonStyle && {
                    ...customNextButtonStyle,
                    color: '#FFFFFF',
                  },
                }}
              >
                {customNextText || 'Siguiente'}
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  projectData: state.ia,
});

const mapDispatchToProps = (dispatch) => ({
  resetProjectData: () => dispatch({type: 'RESET_PROJECT_DATA'}),
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(IALayout));
